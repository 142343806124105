import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

// import paperTexture from "../assets/images/paperTexture.jpg";
import paperTexture2 from "../assets/images/paperTexture2.jpg";

import Container from "@material-ui/core/Container";
// import { red } from '@material-ui/core/colors';

// import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';

// import ReactDOM from 'react-dom';
import Timeline from "react-image-timeline";

import Paper from "@material-ui/core/Paper";
// import Card from '@material-ui/core/Card';

import { Box } from "@material-ui/core";
// import Stepper from '@material-ui/core/Stepper';
// import Step from '@material-ui/core/Step';
// import StepLabel from '@material-ui/core/StepLabel';
// import Button from '@material-ui/core/Button';
// import Link from '@material-ui/core/Link';
// import Typography from '@material-ui/core/Typography';
// import AddressForm from './AddressForm';
// import PaymentForm from './PaymentForm';
// import Review from './Review';

import imageGradLaw from "./../assets/images/50316893_00201_0182_Large 5.JPG";
import imageATJ from "./../assets/images/IMG_8978.jpg";
import imageLSGMI from "./../assets/images/CA1B0C38-1ADD-416B-9899-092900F4AA3E 7.JPG";
import imageBeganLawSchool from "./../assets/images/image1.png";
// import imageSpokeAtLSC from './';
import imageGraduatedUndergrad from "./../assets/images/IMG_9074.png";
import imageCodeX from "./../assets/images/image2.jpg";
// import imageLSC from './../assets/images/image8.png'
import imageLSC from "./../assets/images/image8.jpg";
import imageSanJose from "./../assets/images/sanJose.jpg";
import imageDayOneProjectWork from "./../assets/images/DayOneProjectWork.png";

require("react-image-timeline/dist/timeline.css"); // .scss also available

// const events = {
//     date: new Date(2013, 9, 27),
//     text: "Sed leo elit, pellentesque sit amet congue quis, ornare nec lorem.",
//     title: "Cairo, Egypt",
//     buttonText: 'Click Me',
//     imageUrl: "http://github.com/aaron9000/react-image-timeline/blob/master/src/assets/cairo.jpg?raw=true",
//     onClick: () => {
//         console.log('hello');
//     }
// }

const useStyles = makeStyles((theme) => ({
  JourneyCard: {
    minWidth: 175,
  },
  bg: {
    // backgroundImage: `url(${paperTexture})`,
    backgroundImage: `url(${paperTexture2})`,

    borderBottom: ".5rem solid #474441",
  },
  resumeHeader: {
    // fontFamily: "'Athiti', sans-serif",

    color: "white",
    // backgroundColor: 'rgba(0,150,136,0.7)',
    // backgroundColor: 'rgba(149, 0, 0, 0.8)',
    // backgroundColor: 'rgba(7, 0, 0, 0.8)',
    marginTop: "10px",
    // marginBottom:'10px',

    // textTransform: 'uppercase',
    // radius:'20px'
    borderRadius: "11px",
    // boxShadow: '10px 10px'
    // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  },
  title: {
    // fontWeight: 'bold',
    paddingTop: 40,
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "1.2rem",

    // paddingBottom: -45,

    marginBottom: "-5px",
    // textShadow:'3px 3px #000'
  },
  titleBooks: {
    paddingTop: 40,
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "2rem",
    marginBottom: "-5px",
  },
  subtitle: {
    paddingBottom: 50,
  },
  root: {
    flexGrow: 1,
  },
  arrow: {
    width: 0,
    height: 0,
    borderLeft: ".6rem solid transparent",
    borderRight: ".6rem solid transparent",
    borderBottom: ".6rem solid transparent",
    borderTop: ".6rem solid #474441",
    margin: "0 auto",
  },
  portfolioImg: {
    backgroundColor: "#000",
    overflow: "hidden",
  },
  img: {
    width: "100%",
    opacity: 0.7,
    transform: "scale(1.3)",
    transition: "transform 0.5s, opacity 0.5s",
    "&:hover": {
      opacity: 1,
      transform: "scale(1.06)",
    },
  },
  emoji: {
    fontSize: "3rem",
  },
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    // width:200,
    // marginBottom:'100px',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1100 + theme.spacing(2) * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    //   width:800
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

// const useStyles = makeStyles({

// });

export default function Portfolio() {
  const CustomHeader = (props) => {
    const { title, date2 } = props.event;
    const { customField } = "something";

    return (
      <div className="custom-header">
        {/* <h1>test</h1> */}
        <h1>{title}</h1>
        <h4>{date2}</h4>
        {/* {Object.values(date).map(x => <div>'${date.x}'</div>)} */}
        {/* {console.log(date.prototype.getDate())} */}

        {/* {date.map(x => <div>'${x}'</div>)} */}
        {/* <h1>{Object.keys(date).map(key=>({key}))}</h1> */}

        {/* <h1>{text}</h1> */}

        <p>{customField}</p>
      </div>
    );
  };

  const classes = useStyles();
  // const [events, setEvents] = useState(getSampleData(imageType));
  const events = [
    {
      date: new Date(2021, 3, 2),
      date2: "2021",

      text: "",
      title: "Joined Day One Project Technology Policy Accelerator",
      // buttonText: 'Click Me',
      // imageUrl: imageLSGMI,
      imageUrl: imageDayOneProjectWork,

      // onClick: () => {
      //     console.log('hello');
      // }
    },
    {
      date: new Date(2020, 2, 2),
      date2: "2020",

      text: "",
      title:
        "Began Serving on the San Jose Digital Privacy Advisory Task Force",
      // buttonText: 'Click Me',
      // imageUrl: imageLSGMI,
      imageUrl: imageSanJose,

      // onClick: () => {
      //     console.log('hello');
      // }
    },
    {
      date: new Date(2019, 9, 27),
      date2: "2019",

      text: "",
      title: "CodeX Residential Fellow",
      // buttonText: 'Click Me',
      // imageUrl: imageLSGMI,
      imageUrl: imageCodeX,

      // onClick: () => {
      //     console.log('hello');
      // }
    },
    {
      date: new Date(2019, 9, 27),
      date2: "2019",

      text: "",
      title: "Graduated Law School, Juris Doctorate",
      // buttonText: 'Click Me',
      // imageUrl: "http://github.com/aaron9000/react-image-timeline/blob/master/src/assets/cairo.jpg?raw=true",
      imageUrl: imageGradLaw,

      // onClick: () => {
      //     console.log('hello');
      // }
    },
    {
      date: new Date(2018, 9, 27),
      date2: "2019",

      text: "",
      title: "2019 LSGMI Technology Fellow",
      // buttonText: 'Click Me',
      imageUrl: imageLSGMI,
      // imageUrl: imageATJ,
      // onClick: () => {
      //     console.log('hello');
      // }
    },
    {
      date: new Date(2018, 9, 27),
      date2: "2019",

      text: "",
      title: "Spoke at LSC ITC Conference",
      // buttonText: 'Click Me',
      imageUrl: imageLSC,

      // imageUrl: imageATJ,
      // onClick: () => {
      //     console.log('hello');
      // }
    },
    {
      date: new Date(2018, 9, 27),
      date2: "2018",

      text: "",
      title: "ATJ Technology Fellow ",
      // buttonText: 'Click Me',
      imageUrl: imageATJ,

      // imageUrl: imageATJ,
      // onClick: () => {
      //     console.log('hello');
      // }
    },

    {
      date: new Date(2016, 7, 27),
      date2: "2016",

      text: "",
      title: "Began Law School",
      // buttonText: 'Click Me',
      imageUrl: imageBeganLawSchool,
      // onClick: () => {
      //     console.log('hello');
      // }
    },

    {
      date: new Date(2016, 6, 27),
      date2: "2016",

      text: "",
      title: "Graduated University of Central Florida, Legal Studies B.S.",
      // buttonText: 'Click Me',
      imageUrl: imageGraduatedUndergrad,
      // onClick: () => {
      //     console.log('hello');
      // }
    },
  ];

  //  const classes = useStyles();

  return (
    <section id="portfolio" className={classes.bg}>
      {/* <div className={classes.root}>
                <Grid
                    container
                    justify='center'
                >


                 
                </Grid>
            </div> */}

      <main className={classes.layout}>
        <br></br>
        <Paper
          elevation={7}
          className={classes.paper}
          style={{
            maxHeight: 800,
            overflow: "auto",
            backgroundColor: "rgba(253, 252, 252, 0.1)",
            borderRadius: "16px",
            width: "93%",
            //  marginLeft:'.9rem'
            marginLeft: "2%",

            //  marginLeft:'25px'

            //  backgroundColor:'rgba(0, 0, 0, .1)'
          }}
        >
          <div
            className={classes.resumeHeader}
            style={{
              marginTop: "45px",
            }}
          >
            <br></br>

            <Box
              className="EducationBox"
              style={{
                // marginLeft:'65px',
                borderRadius: "3px",
                //  Shadow:'1px 5px 10px rgb(0,0,0, 0.3)'
              }}
              display="flex"
              // width={'80%'}
              height={4}
              // elevation={10}
              //    bgcolor="rgb(244,67,53, 0.1)"
              //    bgcolor="rgb(0,0,0, 1)"

              //    bgcolor={red[500]}

              //    bgcolor="lightgreen"
              alignItems="center"
              justifyContent="center"
            >
              <Paper
                elevation={5}
                // className={classes.title}
                style={{
                  // background:'black',
                  //  background: 'linear-gradient(to left, #838383, white, white, white, white, white, white )',
                  background:
                    "linear-gradient(to right, black,black, black, black, black ,#070707, #1e1d1d)",
                  // background: 'linear-gradient(to bottom right, black,black,#181818, #2a2929)',
                  whiteSpace: "nowrap",
                  padding: 2,
                  borderRadius: "7px",
                  marginLeft: "10px",
                  //   textAlign:'center'
                  // margin:'auto'
                }}
              >
                <Typography
                  style={{
                    marginRight: "400px",

                    color: "white",
                    minWidth: "10px",
                    fontSize: "2.2rem",
                    fontFamily: "'Montserrat', sans-serif",
                    //  marginLeft:'2%'
                    //  textAlign:'center'
                    // margin:'auto'
                  }}
                  variant="h3"
                  align="center"
                  className={classes.titleVar}
                >
                  <br></br>
                </Typography>

                <Grid style={{ height: "100%" }} container alignItems="center">
                  <Container
                    maxWidth="sm"
                    style={{
                      // backgroundColor: 'linear-gradient(to bottom right, #2a2929, #181818, black, black )',

                      // marginTop: '-70px',
                      marginTop: "-28px",

                      marginBottom: "5px",

                      // backgroundColor:'black'
                    }}
                  >
                    <Grid item>
                      <Typography
                        style={{
                          color: "white",
                          // color:'black',

                          // borderBottom: '.1rem solid black',
                          maxWidth: "250px",
                          fontFamily: "Axiforma",
                          // fontSize:'2rem'
                          // fontWeight:'bold'
                        }}
                        variant="h1"
                        // className={classes.titleBooks}
                        className="titleBooks"
                      >
                        {/* My Journey  */}
                        {/* Stephen Caines */}
                        {/* Where I've Been */}
                        Education &#38; Experience
                      </Typography>
                      <Typography
                        style={{
                          paddingTop: 5,
                          // color:'black',
                          //  fontWeight:'bold',

                          color: "white",
                          fontSize: "0.9rem",
                          fontFamily: "Axiforma",

                          // borderBottom: '.1rem solid black',
                          // maxWidth:'200px'
                        }}
                        variant="subtitle1"
                        className={classes.subTitle}
                      >
                        My Journey
                        {/* My Journey  */}
                        {/* Education &#38; Work  */}
                        {/* &#38;  Accomplishments */}
                      </Typography>
                    </Grid>
                  </Container>
                </Grid>
              </Paper>
            </Box>
            <br></br>
          </div>

          <div
            style={{
              // fontFamily: "'Dancing Script', sans-serif",
              // fontFamily: "'Athiti', sans-serif",
              // fontSize: '1rem',
              fontFamily: "'Montserrat', sans-serif",
              // fontSize: '0.8rem',
              fontSize: "0.7rem",

              marginTop: "10px",

              // backgroundColor:'black',
            }}
          >
            <Timeline
              reverseOrder={true}
              events={events}
              customComponents={{ header: CustomHeader }}
            />
          </div>
        </Paper>
        {/* <Copyright /> */}
      </main>
    </section>
  );
}
