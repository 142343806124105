import React, {useState, useEffect} from 'react';
import {Route, Switch} from "react-router";
import './App.css';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';


import Home from './pages/Home';
import CurrentProjects from './pages/CurrentProjects';
import Media from './pages/Media';
import ContactMe from './pages/ContactMe';

// import Resume from './pages/Resume';

// import NavBar from "./components/Navbar";
// import Intro from "./components/Intro";
// import AllRightReserved from "./components/AllRightsReserved";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';


import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles'


import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

// import logo from './assets/images/logoMock3.png';
// import logo2 from './assets/images/logoMock.png';

import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import axios from './axios-stories';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';













const sections = [
    { title: 'Home', url: '#' },
    // { title: 'Current Projects', url: '#projects' },
    // { title: 'Good Reads', url: '#' },
    { title: 'Current Projects', url: '#projects' },
    { title: 'Media', url: '#media' },


    // { title: 'Contact Me', url: '#' },
    // { title: 'Opinion', url: '#' },
    // { title: 'Science', url: '#' },
    // { title: 'Health', url: '#' },
    // { title: 'Style', url: '#' },
    // { title: 'Travel', url: '#' },
  ];


const useStyles = makeStyles((theme) => ({
    '@global': {
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
    },
    // appBar: {
    //   borderBottom: `1px solid ${theme.palette.divider}`,
    // },
    // toolbar: {
    //   flexWrap: 'wrap',
    // },
    // toolbarTitle: {
    //   flexGrow: 1,
    // },
    // link: {
    //   margin: theme.spacing(1, 1.5),
    // },
    // heroContent: {
    //   padding: theme.spacing(8, 0, 6),
    // },
    // cardHeader: {
    //   backgroundColor:
    //     theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    // },
    // cardPricing: {
    //   display: 'flex',
    //   justifyContent: 'center',
    //   alignItems: 'baseline',
    //   marginBottom: theme.spacing(2),
    // },
    footer: {
    //   borderTop: `1px solid ${theme.palette.divider}`,
    //   marginTop: theme.spacing(8),
    //   paddingTop: theme.spacing(3),
    //   paddingBottom: theme.spacing(3),
    //   [theme.breakpoints.up('sm')]: {
    //     paddingTop: theme.spacing(6),
    //     paddingBottom: theme.spacing(6),
    //   },
    },
    toolbar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      toolbarTitle: {
        fontFamily: "'Josefin Sans', sans-serif",
        // fontFamily:'Roboto',
        flex: 1,
        marginTop:'4px',
        marginBottom:'7px',
        // fontWeight: 'bold',
        fontSize:'2.5em'
    
    
      },
      toolbarSubtitle:{
        // fontFamily:'Merriweather',
        // color:'#FF472A'
        // FF872A
    
      },
      toolbarTitleText: {
        // marginRight:'auto'
    
        // display:'none'
        // marginTop:'-34px',
        // marginBottom:'10px'
        // color: titleTextColor
            color: props => props.backgroundColor,
    
      },
      toolbarSecondary: {
        justifyContent: 'space-between',
        overflowX: 'auto',
      },
      toolbarLink: {
        fontFamily: "'Josefin Sans', sans-serif",
          // fontFamily: "'Archivo', sans-serif",

        padding: theme.spacing(1),
        flexShrink: 0,
        // textTransform: 'uppercase',
        fontSize:'1.4em',
        color: props => props.backgroundColor,
        textDecoration: 'none',

        // "&:focus, &:hover, &:visited, &:link, &:active" :{
        //     textDecoration: 'none',
        // }
        " &:hover" :{
          textDecoration: 'none',
          // fontSize:'1.4rem',
          fontWeight:'bold',

      },
      " &:active" :{
        // textDecoration: 'none',
        // fontSize:'1.4rem',
        // fontWeight:'bold',

    }
    
      },
      logo: {
        height:'5em',
        // marginTop:'-2px',
    
        marginBottom:'-3px',
        // marginRight:'auto'
    
      },
      tabs:{
        marginBottom:'-10px',
        marginLeft:'-10px',
        // textTransform: 'uppercase'
        // marginRight:'100px'
        // paddingRight:'100px'
    
      },
      toolbarMargin: {
        ...theme.mixins.toolbar
      },
      // appbarcontainer:{
      //   // backgroundColor: "white"
      // }
      button:{
        // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        background: props => props.buttonColor,
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      },
      burgerButton:{
        // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        // background: props => props.buttonColor,
        // borderRadius: 3,
        // border: 0,
        color: props => props.backgroundColor,
        // color:'white'
        // height: 48,
        // padding: '0 30px',
        // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      },
    
  }
  )
  );



  function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ 
      disableHysteresis: true,
      // threshold: 0,
      threshold: 330,
        
  
      target: window ? window() : undefined 
  
    });
  
  
    return React.cloneElement((
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
      ), {
    //   elevation: trigger2 ? 4 : 0,
        elevation: 0,

    });
  
  
  
  
    
  }


  

export default function App(props) {

  const [frStories, setFRStories] = useState(null);









  useEffect(() => {
    async function fetchData() {
      axios.get('https://caines-tech.firebaseio.com/11q7lYmT-k3yBaBYV3EuUO-K9lWzyfdSnYZ0G2jWPmx0.json')
  .then(
    response=>{setFRStories(response.data)})


    .catch(error => {
      // console.log(error.response)
  });
      // You can await here
      // const response = await MyAPI.getData(someId);
      // ...
    }
    fetchData();
  }, []); 








// if (frStories)
// {console.log(frStories)}



    const classes = useStyles();

    const theme = useTheme();

    const matches = useMediaQuery(theme.breakpoints.down("sm"));

    const [openDrawer, setOpenDrawer] = useState(false)

    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

    // const { sections, title } = props;








  const routes = (
    <Switch>
        <Route path='/projects' exact 
        // component={CurrentProjects}
        render={(props) => <CurrentProjects 
          frStories={frStories}

          {...props}
          />}
        />
        
        <Route path='/media' exact 
        // component={Media} 
        render={(props) => <Media 
          // frStories={frStories}
          // {...props}
        /> }
        />
        <Route path='/contact' exact 
        // component={ContactMe} 
        render={(props) => <ContactMe 
          // {...props}
        /> }
        />
        <Route path='/' exact 
        // component={Home} 
        render={(props) => <Home 
          // frStories={frStories}
          // facialRecognitionStories2='test' 
          // facialRecognitionStories2={frStories}

          frStories={frStories}
          // {...frStories}
          {...props}
          // facialRecognitionStories={testVar && testVar}

          
        /> }
        />


        {/* <Route path='/projects' component={() => {
            window.location.href = 'https://www.linkedin.com/in/henry-lo-0621/';
            return null;
        }}/> */}
        {/* <Route path='/gitHub' component={() => {
            window.location.href = 'https://github.com/Henry-C-Lo/';
            return null;
        }}/> */}
    </Switch>
)


const tabs = (
    <React.Fragment>
      <div 
      className={classes.tabs} 
      style={{
        // marginBottom:'-90px'
      }}>
        {sections.map(section => (
          <Link
            
            // color="inherit"
            // color="white"
            noWrap
            key={section.title}
            variant="body2"
            href={section.url}
            className={classes.toolbarLink}
            style={{color:'white', 
            
          
          
          
          }}
            activeStyle={{color:'red'}}


          >
            {section.title}
          </Link>
        ))}

   

<Link
            
            // color="inherit"
            // color="white"
            noWrap
            // key={section.title}
            variant="body2"
            href='#contact'
            className={classes.toolbarLink}
            style={{
              color:'white',
              // color:'#CA0C0C',
              // color:'#FD191B',



              // color:'white',
            
            // fontFamily: "'Montserrat', sans-serif",
              // fontSize: '0.8rem',
            // textShadow: '1px 1px 2px'

            // textShadow: '1px 1px 5px #F50A0A'

            
          }}
          

          >
            Contact Me
            {/* {section.title} */}
          </Link>


       



        {/* <Button 
        className={classes.button}
        variant="contained" 
        // color={ props.buttonColor}
        >test</Button> */}
      </div>

    </React.Fragment>
  );


const drawer = (
    <React.Fragment>
    <SwipeableDrawer 
    disableBackdropTransition={!iOS} 
    disableDiscovery={iOS} 
    open={openDrawer} onClose={() => setOpenDrawer(false)} onOpen={() => setOpenDrawer(true)}>



    
      {/* Example Drawer */}
    
      {/* <div className={classes.toolbar} /> */}
      <br></br>
      <br></br>
      <br></br>
 

      <Divider />
      <List>
        <ListItem button key={1} 
        onClick= {() => {setOpenDrawer(false)}}
        // component={Link} to='/media'
        >
          {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
          {/* <ListItemText primary={'Home'} /> */}
          <Link
            
            // color="inherit"
            // color="white"
            noWrap
            key={'Home'}
            variant="body2"
            href={"#"}
            className={classes.toolbarLink}
            style={{color:'black'}}

          >
            {'Home'}
          </Link>
        </ListItem>
       
        <ListItem button key={2}
        onClick= {() => {setOpenDrawer(false)}}>
          {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
          {/* <ListItemText primary={'Media'} /> */}
          <Link
            
            // color="inherit"
            // color="black"
            noWrap
            key={'Projects'}
            variant="body2"
            href={"#projects"}
            className={classes.toolbarLink}
            style={{color:'black'}}

          >
            {'Projects'}
          </Link>
        </ListItem>
        <ListItem button key={3}
        onClick= {() => {setOpenDrawer(false)}}>
          {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
          {/* <ListItemText primary={'Media'} /> */}
          <Link
            
            // color="inherit"
            // color="white"
            noWrap
            key={'Media'}
            variant="body2"
            href={"#media"}
            className={classes.toolbarLink}
            style={{color:'black'}}

          >
            {'Media'}
          </Link>
        </ListItem>
        <ListItem button key={4}
        onClick= {() => {setOpenDrawer(false)}}>
          {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
          {/* <ListItemText primary={'Media'} /> */}
          <Link
            
            // color="inherit"
            // color="white"
            noWrap
            key={'Contact'}
            variant="body2"
            href={"#contact"}
            className={classes.toolbarLink}
            style={{color:'black'}}

          >
            {'Contact'}
          </Link>
        </ListItem>
        {/* {['Home', 'Media', 'Current Projects', 'Contact'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))} */}
      </List>
      {/* <Divider /> */}
      {/* <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}



    
    </SwipeableDrawer>
    <IconButton onClick={()=> setOpenDrawer(!openDrawer)} >
      
        <MenuIcon className={classes.burgerButton} 
        style={{color:'white', fontSize:'2rem'}} 
        ></MenuIcon>
    </IconButton>
    
    </React.Fragment>
    )




    





    return (


<React.Fragment>









<React.Fragment>


<HideOnScroll {...props}         
       
       >

{/* <HideOnScroll {...props}         
       
> */}
        <AppBar 
        
        // color={navBackground}
        color={'transparent'}

        // color='secondary'


        
        // boxShadow: 'none
        // variant="dense"
        // classes={{root: classes.appbarcontainer}}
        >
          {/* <Toolbar>
            <Typography variant="h6">Scroll to Hide App Bar</Typography>
          </Toolbar> */}


      <Toolbar className={classes.toolbar}         style={{ 
        // borderColor: 'rgba(8, 233, 255, 0.01)' 
        border:'none'
    }}
>
        {/* <Button size="small">Subscribe</Button> */}
        {/* <img alt="website logo" src={logo2} className={classes.logo}>
        </img> */}

        <div style={{
          marginRight:'auto'
          // overflow: "hidden", 
          // textOverflow: "ellipsis", 
          // width: '11rem'
          }}>
      
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          // align="center"
          noWrap
          className={classes.toolbarTitle}
        >

{/* <img alt="website logo" src={logo} className={classes.logo}>
        </img> */}

{/* <span className= {classes.toolbarTitleText}>
{title}
</span> */}



                {/* <p className={classes.toolbarSubtitle} style={{
                  marginTop: '5px',
                  fontSize: '14px',
                  textTransform: 'uppercase',
                  paddingTop: '0px'

                }}
                >{'Planta Empacadora Y Exportadora '}
                  <br></br>
                  {'De Frutos y Vegetales Frescos'}
                </p> */}

{/* </span> */}


        </Typography>
        </div>
        {/* <IconButton>
          <SearchIcon />
        </IconButton> */}
        {/* <Button variant="outlined" size="small">
          Sign up
        </Button> */}


{matches ? drawer : tabs}
{/* {matches ? null : tabs} */}

{/* {tabs} */}
{/* <div className={classes.tabs} style={{
  // marginBottom:'-90px'
}}>
        {sections.map(section => (
          <Link
            color="inherit"
            noWrap
            key={section.title}
            variant="body2"
            href={section.url}
            className={classes.toolbarLink}
          >
            {section.title}
          </Link>
        ))}

</div> */}


      </Toolbar>

      </AppBar>
      </HideOnScroll>
      {/* <div style={{position:'absolute', zIndex:'3px'}}>
      {matches ? drawer : null}

      </div> */}

      {/* <div className={classes.toolbarMargin}/> */}


      {/* <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
        {sections.map(section => (
          <Link
            color="inherit"
            noWrap
            key={section.title}
            variant="body2"
            href={section.url}
            className={classes.toolbarLink}
          >
            {section.title}
          </Link>
        ))}
      </Toolbar> */}
    </React.Fragment>





    



        
    <div 
    className="App"
    color={'transparent'}
    >
                

         {/* <AppBar position="static" 
        //  color="secondary"
        color={'transparent'}

        //  style={{background:'transaprent'}} 
        //  style={{ background: 'transparent', boxShadow: 'none'}}
        //  color="default" 
        // color="transparent"
        // style={{display:'none'}}
         elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}  
                color={'transparent'}

    //    style={{ background: 'transparent', boxShadow: 'none'}}

        // color="secondary"
        >
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
            Company name
          </Typography>
          <nav>
            <Link variant="button" color="textPrimary" href="#" className={classes.link}>
              Features
            </Link>
            <Link variant="button" color="textPrimary" href="#" className={classes.link}>
              Enterprise
            </Link>
            <Link variant="button" color="textPrimary" href="#" className={classes.link}>
              Support
            </Link>
          </nav>
          <Button href="#" color="primary" variant="outlined" className={classes.link}>
            Login
          </Button>
        </Toolbar>
      </AppBar>  */}





        {/* <NavBar /> */}
        {/* <Intro/> */}
        <main>
            {routes}
        </main>
        {/* <AllRightReserved/> */}
    </div>
    </React.Fragment>
  );
}

