import React from "react";
// import resume from '../assets/images/resume.jpg';
// import Inspiration from "../components/Inspiration";

// import StephenAvatar from '../components/StephenAvatar';
// import About from "../components/About";
// import Intro from "../components/Intro";

// import image1 from './../assets/images/caroImage.jpg';
// import image1 from './../assets/images/IMG_9074.png';

// import image1 from "./../assets/images/hero24.jpg"

// import image2 from './../assets/images/caroImage.jpg';
// import image3 from './../assets/images/caroImage.jpg';
// import hero2 from "../assets/images/hero2.jpg";
// import screenshot1 from './../assets/images/screenshot1.png'
import screenshot2 from "./../assets/images/screenshot2.png";
import screenshot3 from "./../assets/images/screenshot3.png";
import screenshot4 from "./../assets/images/screenshot4.png";
import screenshot5 from "./../assets/images/screenshot5.png";

import screenshot6 from "./../assets/images/screenshot6.jpeg";
import screenshot7 from "./../assets/images/screenshot7.jpeg";
import screenshot8 from "./../assets/images/screenshot8.png";
import screenshot9 from "./../assets/images/Digital_Privacy_Advisory.png";
import screenshot10 from "./../assets/images/PGH_AI.png";
import screenshot11 from "./../assets/images/DayOneProject.png";

// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { makeStyles, useTheme } from "@material-ui/core/styles";

// import paperTexture from "../assets/images/paperTexture2.jpg";
import CssBaseline from "@material-ui/core/CssBaseline";
// import hero from "../assets/images/hero.jpg";
// import imageAurora from "./../assets/images/aurora-kreativ-UN4cs4zNCYo-unsplashDark.jpg"
// import imageAurora from "./../assets/images/jason-leung-r93UZeT3AQE-unsplash.jpg"
import imageAurora from "./../assets/images/maarten-van-den-heuvel-gZXx8lKAb7Y-unsplash.jpg";

import imageCircuit from "./../assets/images/hero24blue.jpg";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
// import Avatar from '@material-ui/core/Avatar';
import IconButton from "@material-ui/core/IconButton";
// import Typography from '@material-ui/core/Typography';
import { red } from "@material-ui/core/colors";
// import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from "@material-ui/icons/Share";
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import whiteImage from "../assets/images/plain-white-background.jpg";

// import { Box } from '@material-ui/core';
// import Paper from '@material-ui/core/Paper';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Avatar from "@material-ui/core/Avatar";
import SwipeableViews from "react-swipeable-views";

import Paper from "@material-ui/core/Paper";

// import './../App.css';

import "./../style/media.css";

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.3)",
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },

  bg: {
    // backgroundImage: `url(${hero2})`,
    backgroundImage: `url(${imageAurora})`,

    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    // height: '85vh',
    // height: '100vh',
    height: "100%",

    // borderBottom: '.5rem solid #009688',
    // borderBottom: '.5rem solid #960000',
    // borderBottom: '.5rem solid #821D1D',
    // borderBottom: '.14rem solid #FF1717',
    borderBottom: ".1rem solid #B90202",

    // borderBottom: '.1rem solid #050000',

    position: "relative",
  },
  noContainerGrid: {
    // paddingBottom:'1000px',
    // marginTop:'100px',
    // marginBottom:'100px',

    // height:'110%',
    width: "100%",
    position: "absolute",
    left: "50%",
    // top: '50%',
    top: "60%",

    // top: '37%',

    transform: "translate(-50%, -50%)",
  },
  titleCard: {
    fontSize: "1.6rem",
    // color:'black'
    marginTop: "10px",
    marginBottom: "7px",
  },

  titleVar: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "2.7rem",

    // color: 'white',
    // // fontFamily: 'Helvetica',
    // fontWeight: 'bold',
    // textTransform: 'uppercase',
    // // top: '10%',
    // marginTop:'-1%',
    // padding: 10,
    // fontFamily: "'Athiti', sans-serif",
    // fontSize:'1.3rem'
  },
  titleBook: {
    fontFamily: "'Montserrat', sans-serif",

    // color: 'white',
    // // fontFamily: 'Helvetica',
    // fontWeight: 'bold',
    // // textTransform: 'uppercase',
    // // top: '10%',
    // marginTop:'-1%',
    // padding: 10,
    // fontFamily: "'Athiti', sans-serif",
    // fontSize:'0.7rem'
  },
  title2: {
    color: "white",
    // fontFamily: 'Helvetica',
    fontWeight: "bold",
    // textTransform: 'uppercase',
    // top: '10%',
    marginTop: "-1%",
    padding: 10,
    fontFamily: "'Athiti', sans-serif",
    fontSize: "1.3rem",
  },
  avatar: {
    // color: "white",
    // opacity: "0",
    // margin: ".2rem auto",
    width: 60,
    height: 60,
    // border: ".1rem solid white",
    boxShadow: "0 .3rem .2rem rgba(0, 0, 0, 0.3)",
  },
  avatar2: {
    // opacity: "0",
    margin: ".2rem auto",
    width: 60,
    height: 60,
    border: ".1rem solid white",
    boxShadow: "0 .3rem .2rem rgba(0, 0, 0, 0.3)",
  },
  bigIndicator: {
    height: 5,
  },
  quotes: {
    color: "white",
    textAlign: "center",
    // fontFamily:'Helvetica',
    fontFamily: "'Athiti', sans-serif",
  },
  quotationMark: {
    fontSize: 60,
  },
  quote: {
    margin: "0 auto",
  },
  name: {
    fontFamily: "'Athiti', sans-serif",
    fontSize: "1rem",
  },

  rootCard: {
    // maxWidth: 345,
    // maxWidth: 545,
    maxWidth: 945,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
    // backgroundColor: red[400],

    // backgroundColor: '#030000',
  },

  root: {
    // backgroundImage: `url(${hero})`,
    backgroundImage: `url(${imageCircuit})`,
    backgroundPosition: "center",
    // backgroundSize: "contain",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    height: "95vh",
  },
  title: {
    padding: "2rem 0",
    textAlign: "center",
    color: "white",
    // borderBottom: '.1rem solid white',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: "3.3rem",
    position: "relative",

    borderBottom: ".1rem solid white",

    // border-image: linear-gradient(to left, #743ad5, #d53a9d);
  },
  subTitle: {
    textAlign: "center",
    color: "white",
    // fontFamily: 'Playfair Display',
    // fontSize: '1.2rem',
    fontSize: "1.6rem",

    // fontFamily: "'Athiti', sans-serif",
    // fontFamily: "'Noto Sans', sans-serif",
    // fontFamily: "'Caudex', sans-serif",
    // fontFamily: "'Nobile', sans-serif",

    // fontFamily: 'Fauna One',
    // fontFamily: 'Permanent Marker',
    padding: 10,
  },
}));

export default function Media() {
  const classes = useStyles();

  const theme = useTheme();

  // const matches = useMediaQuery(theme.breakpoints.down("sm"));

  // const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  // const [expanded, setExpanded] = React.useState(false);
  const [expanded] = React.useState(false);
  //
  // const handleExpandClick = () => {
  //     setExpanded(!expanded);
  // };

  // const classes = useStyles()
  // const theme = useTheme();
  // const [value, setValue] = React.useState(3);
  const [value, setValue] = React.useState(3);

  // const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  const LawDirectoryContainer = (
    <div
      style={
        {
          // overflow: 'auto',
          // marginTop:'-200px !important'
        }
      }
      className="StanfordSiteScreenshot"
    >
      <img
        style={{
          display: "block",
          // marginTop:'-100px !important'
          // maxWidth:'10% !important'
        }}
        width="100%"
        // width='auto'

        src={screenshot2}
        alt="screenshot of Stanford Law Directory Website"
      />
    </div>
  );

  const LSCContainer = (
    <div
      style={
        {
          // overflow: 'auto',
          // maxWidth: 700
        }
      }
    >
      <img
        style={{ display: "block" }}
        width="100%"
        src={screenshot5}
        alt="screenshot of Stephen's profile on LSC ITC Conference 2019 website"
      />
    </div>
  );

  const MeetCodexContainer = (
    <div
      //   className='MeetCodexVideo'
      style={
        {
          // minWidth: '860px !important',
          // minHeight: 'auto'
        }
      }
    >
      <iframe
        style={
          {
            //  minWidth:'260px'
          }
        }
        title="Meet Codex Youtube video"
        className="MeetCodexVideo"
        // width="100%"
        // height="auto"
        height="100%"
        // width="560"
        // height="315"

        src="https://www.youtube.com/embed/SDf50GNze7I"
      />
    </div>
  );

  const ForbesContainer = (
    <div
      style={
        {
          // overflow: 'auto',
          // maxWidth: 600
        }
      }
    >
      <img
        style={{ display: "block" }}
        width="100%"
        src={screenshot8}
        alt="screenshot of Forbes article"
      />
    </div>
  );

  const OurDataContainer = (
    // <img
    // width="50%"
    // // width="560"
    // width="50%"

    // // height="315"
    // src={screenshot1} />
    <div
      style={{
        overflow: "auto",
        // minWidth: '600px'
      }}
      className="OurDataPodcastOne"
    >
      <iframe
        title="Stephen's podcast on Facial Recognition"
        width="100%"
        height="300"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/747338263&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
      ></iframe>
    </div>
  );

  const ATJContainer = (
    <div
      style={
        {
          // maxWidth: 600
          // overflow: 'auto',
        }
      }
    >
      <img
        style={{ display: "block" }}
        width="100%"
        src={screenshot4}
        alt="screenshot ATJ Fellowship article on Miami Law website"
      />
    </div>
  );

  const PopupContainer = (
    <div
      style={
        {
          // overflow: 'auto',
          // maxWidth: 600
        }
      }
    >
      <img
        style={{ display: "block" }}
        width="100%"
        src={screenshot3}
        alt="screenshot of Pop-Up class article on Stanford Law website"
      />
    </div>
  );

  const FacesOfFRContainer = (
    <div
      style={
        {
          // overflow: 'auto',
          // maxWidth: 600
        }
      }
    >
      <img
        style={{ display: "block" }}
        width="100%"
        src={screenshot7}
        alt="screenshot of The Many Faces of FR "
      />
    </div>
  );

  const LIContainer = (
    <div
      style={
        {
          // overflow: 'auto',
          // maxWidth: 600
        }
      }
    >
      <img
        style={{ display: "block" }}
        width="100%"
        src={screenshot6}
        alt="screenshot of L.I. in Sub-Saharan Africa website"
      />
    </div>
  );

  const XXZ = (
    <div
      style={
        {
          // overflow: 'auto',
          // maxWidth: 600
        }
      }
    >
      <img
        style={{ display: "block" }}
        width="100%"
        src={screenshot9}
        alt="screenshot of Digital Privacy Advisory Task Force website"
      />
    </div>
  );

  const XZZ = (
    <div
      style={
        {
          // overflow: 'auto',
          // maxWidth: 600
        }
      }
    >
      <img
        style={{ display: "block" }}
        width="100%"
        src={screenshot10}
        alt="screenshot of PGH.AI website"
      />
    </div>
  );
  const ZZZ = (
    <div
      style={
        {
          // overflow: 'auto',
          // maxWidth: 600
        }
      }
    >
      <img
        style={{ display: "block" }}
        width="100%"
        src={screenshot11}
        alt="screenshot of Day One Project Cohort website"
      />
    </div>
  );

  const avatarData = [
    {
      // number: 2,
      index: 0,
      name: "Stanford Law Directory",
      // src: jonathanGillette,
      // quote: 'A goal is just a dream with a deadline.',
      description: "",
      author: " ",
      bookName: "",
      url: "https://law.stanford.edu/directory/stephen-caines/",
      // ,color:'#30b7d5'
      color: "4B3232",
      div: LawDirectoryContainer,
    },

    {
      // number: 1,
      index: 1,

      name: "LSC ITC Conference 2019 ",

      description: "",
      author: " ",
      bookName: "",
      url: "https://lscitc2019.sched.com/speaker/sxc1302",
      // ,color:'#30b7d5'
      color: "4B3232",
      div: LSCContainer,
    },

    {
      index: 2,
      name: "Meet CodeX",

      description:
        "Written by my former law professor and mentor Mary Anne Franks, this book places constitutional fundamentalism under a critical microscope and questions the antidemocratic consequences of blindly following this document. Rife with clear examples of where fundamentalism is incongruent with our modern world, she brilliantly questions current perceptions of this founding document.",
      author: " ",
      bookName: "",
      url: "https://www.youtube.com/watch?v=SDf50GNze7I",
      color: "4B3232",
      div: MeetCodexContainer,
      //    ,color:'#30b7d5'
    },

    {
      index: 3,
      name: "Forbes",

      description: "",
      author: " ",
      bookName: "",
      url:
        "https://www.forbes.com/sites/lanceeliot/2020/04/16/futurelaw-2020-showcases-how-tech-is-transforming-the-law-including-the-impacts-of-ai/#60eff76520c3",
      // , color: '4B3232'
      // , color: '#d359cf'
      // , color: '#b44eb1'
      color: "#30b7d5",

      // ,color:'4B3232'
      div: ForbesContainer,
    },

    {
      index: 4,
      name: "Podcast - Our Data",

      description: "",
      author: " ",
      bookName: "",
      url:
        "https://law.stanford.edu/2020/01/30/our-data-episode-1-stanford-codex-fellow-stephen-caines-discusses-facial-recognition-and-ai/",
      color: "#30b7d5",
      // ,color:'4B3232'
      div: OurDataContainer,
    },

    {
      // number: 0,
      index: 5,

      name: "ATJ Fellowship",

      description: "",
      author: " ",
      bookName: "",
      url:
        "https://www.law.miami.edu/news/2019/april/access-justice-tech-fellowship-launches-students-cutting-edge-legal-issues",
      color: "#30b7d5",
      div: ATJContainer,
    },

    {
      index: 6,
      name: "Popup Class",

      description: "",
      author: " ",
      bookName: "",
      url:
        "https://law.stanford.edu/press/stanford-law-pop-up-class-explores-artificial-intelligence-and-the-law/",
      color: "#30b7d5",
      // ,color:'4B3232'
      div: PopupContainer,
    },

    {
      index: 7,
      name: "The Many Faces of FR",

      description: "",
      author: " ",
      bookName: "",
      url:
        "https://conferences.law.stanford.edu/futurelaw2020/sessions/legal-innovation-in-sub-saharan-africa/",
      color: "#b44eb1",

      // , color: '4B3232'
      // ,color:'4B3232'
      div: FacesOfFRContainer,
    },

    {
      index: 8,
      name: "L.I. in Sub-Saharan Africa",

      description: "",
      author: " ",
      bookName: "",
      url:
        "https://conferences.law.stanford.edu/futurelaw2020/sessions/legal-innovation-in-sub-saharan-africa/",
      // , color: '4B3232'
      // , color: '#d359cf'
      color: "#b44eb1",

      // ,color:'4B3232'
      div: LIContainer,
    },

    {
      index: 9,
      name: "Digital Privacy Advisory Task Force",

      description: "",
      author: " ",
      bookName: "",
      url:
        "https://www.sanjoseca.gov/your-government/city-manager/civic-innovation-digital-strategy/digital-privacy",
      // , color: '4B3232'
      // , color: '#d359cf'
      color: "#b44eb1",

      // ,color:'4B3232'
      div: XXZ,
    },

    {
      index: 10,
      name: "PGH.AI",

      description: "",
      author: " ",
      bookName: "",
      url:
        "https://www.eventbrite.com/e/the-sword-the-shield-how-gov-use-of-tech-can-help-or-harm-the-public-tickets-138345317569?aff=erelexpmlt",
      // , color: '4B3232'
      // , color: '#d359cf'
      color: "#b44eb1",

      // ,color:'4B3232'
      div: XZZ,
    },
    {
      index: 11,
      name: "Day One Project Cohort",

      description: "",
      author: " ",
      bookName: "",
      url: "https://www.dayoneproject.org/tech-policy-cohort",
      // , color: '4B3232'
      // , color: '#d359cf'
      color: "#b44eb1",

      // ,color:'4B3232'
      div: ZZZ,
    },
  ];

  function TabPanel(props) {
    const { children } = props;
    const classes = useStyles();

    return (
      <div className={classes.quotes}>
        {/* <QuoteIcon className={classes.quotationMark} /> */}
        <br></br>
        <br></br>
        {/* <br></br> */}

        <Typography variant="h5">{children}</Typography>
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: "black" }}>
      {/* <Intro/> */}

      <Paper
        className={classes.mainFeaturedPost}
        style={{ backgroundImage: `url(${imageCircuit})` }}
      >
        {/* Increase the priority of the hero background image */}
        {
          <img
            style={{ display: "none" }}
            src={imageCircuit}
            alt={"post.imageText"}
          />
        }
        <div className={classes.overlay} />
        <section
          id="media"
          // className={classes.root}
        >
          <CssBaseline />
          <div
            style={{
              color: "white",
              // display:(matches ? 'none' : '')
            }}
          >
            {/* {matches ? <div>matches</div> : <div>doesnt match</div>} */}
          </div>

          <Grid
            // style={{ height: '100%' }}
            style={{ height: "85vh" }}
            container
            alignItems="center"
          >
            <React.Fragment>
              <Container
                //  maxWidth='sm'
                style={{
                  // maxWidth:'82vw',
                  maxWidth: "78vw",
                  // maxWidth:'58vw',
                  marginTop: "-50px",
                  // backgroundColor: 'black',
                  background:
                    "linear-gradient(to right, rgba(18, 0, 0, .9), rgba(18, 0, 0, 1), rgba(0, 0, 0, .8), rgba(0, 0, 0, .6),  rgba(0, 0, 0, .8), rgba(0, 0, 0, .8), rgba(0, 0, 0, .7) , rgba(15, 8, 08, 0.8), rgba(3, 14, 7, 0.6), rgba(3, 4, 7, 0.9) )",
                  backdropFilter: "blur(2px)",

                  borderRadius: "36px",
                  //   boxShadow:'5px 10px 10px black'
                  // boxShadow: '1px 1px 3px black',

                  // borderWidth: '3px',
                  // borderStyle: 'solid',
                  // borderImage: 'linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) , #30b7d5) 100% 30%',
                }}
              >
                <Grid
                  item
                  // xs={6}
                  // style={{}}
                >
                  <Typography
                    variant="h1"
                    className={classes.title}
                    // className="modulez"

                    style={{
                      textShadow: "1px 1px 10px black",
                      fontFamily: "Axiforma",
                    }}
                  >
                    Media
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.subTitle}
                    style={{
                      textShadow: "1px 1px 10px black",
                      fontFamily: "Archivo",
                      fontSize: "1.5rem",
                      color: "#e2dfdf",
                    }}
                  >
                    Past &#38; Present Work
                  </Typography>
                  <br></br>
                </Grid>
              </Container>
            </React.Fragment>
          </Grid>
        </section>
      </Paper>

      {/* <Inspiration/> */}
      <Paper
        className={classes.mainFeaturedPost}
        style={{ backgroundImage: `url(${imageAurora})` }}
      >
        {/* Increase the priority of the hero background image */}
        {
          <img
            style={{ display: "none" }}
            src={imageAurora}
            alt={"post.imageText"}
          />
        }
        <div className={classes.overlay} />
        <section
          id="inspiration"
          // className={classes.bg}
          // style={{marginBottom:'2000px'}}
          style={{
            // borderTop:'.2rem solid white'
            borderTop: ".2rem solid white",
          }}
        >
          <div
          // className={classes.noContainerGrid}
          // style={{marginBottom:'media2000px'}}
          >
            <br></br>
            <br></br>
            <br></br>
            {/* <br></br> */}
            {/* <br></br> */}

            {/* <Typography
                    variant='h3'
                    align='center'
                    className={classes.title}
                >
                    Reading List
                </Typography> */}

            {/* <Typography
                    // variant='h3'
                    align='center'
                    className={classes.title2}
                >
                    Subtitle..
                </Typography> */}
            <br></br>

            <AppBar
              // className="tabsMedia"

              // className={'crazyclass'}
              position="static"
              style={{
                margin: "1rem 0",
                // display:'none'

                // marginTop:'600px',
                // marginTop:(matches ? '200px' : '600px') ,

                //  display:(matches ? 'none' : '')
              }}
            >
              <Tabs
                // className="tabsMedia"

                // className='crazyclass'

                // className="tabsMedia"
                // style={{marginTop:'300px'}}
                style={{ marginLeft: "-60px" }}
                classes={{ indicator: classes.bigIndicator }}
                value={value}
                onChange={handleChange}
                // variant="fullWidth"
                variant="scrollable"
                //
              >
                {/* <div 
                            style={{marginLeft:'750px'}}
                            className={'marginTabFix'}
                            >
                                    
                            </div> */}

                {avatarData.map((data) => (
                  <Tab
                    // style={true}
                    // style={ (data.name === 'Stanford Law Directory') ? { flexGrow: 1, display: 'none'} : {flexGrow: 1}}
                    // style={ (data.index === 0) ? { flexGrow: 1, marginLeft: '140px'} : {flexGrow: 1}}
                    className={data.index === 0 ? "tabFix" : null}
                    // className='tabFixx'

                    style={{
                      flexGrow: 1,
                      // marginLeft: '100px',
                      // flexShrink:0
                      // margin:'auto'
                    }}
                    key={data.name}
                    label={
                      <div>
                        {/* <Avatar
                                            // alt={data.name}
                                            // src={whiteImage}

                                            alt={data.name}
                                            src={data.src}
                                            className={classes.avatar}
                                        /> */}
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          {/* <Grid item>

                                        </Grid> */}

                          <Avatar
                            aria-label="recipe"
                            className={classes.avatar2}
                            style={{
                              // textAlign: 'center'
                              backgroundColor: data.color,
                              //   backgroundColor:'red'
                            }}
                          >
                            {data.name.charAt(0)}
                            {/* R */}
                          </Avatar>
                        </Grid>
                        <Typography variant="caption" className={classes.name}>
                          {data.name}
                        </Typography>
                      </div>
                    }
                  />
                ))}
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              {avatarData.map((data) => (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  // item xs={10} sm={6}
                  // className={classes.quote}
                  key={data.name}
                >
                  <TabPanel
                    value={value}
                    index={data.index}
                    dir={theme.direction}
                  >
                    <Card
                      // className={classes.rootCard}
                      className="loopedCard"
                    >
                      <CardHeader
                        avatar={
                          //   <Avatar aria-label="recipe" className={classes.avatar}>
                          //     {/* R */}
                          //   </Avatar>
                          <Avatar
                            alt={data.name}
                            src={whiteImage}
                            // alt={data.name}
                            // src={data.src}
                            className={classes.avatar}
                          />
                        }
                        action={
                          <IconButton aria-label="settings">
                            <MoreVertIcon />
                          </IconButton>
                        }
                        // title="Shrimp and Chorizo Paella"
                        // title={data.bookName}
                        title={data.name}
                        // subheader="September 14, 2016"
                        subheader={data.author}
                        classes={{
                          title: classes.titleCard,
                          // subheader: {
                          //     // padding:'10 30px'
                          //     fontSize:'3rem'
                          // }
                        }}
                      />
                      {/* <CardMedia
        className={classes.media}
        image="/static/images/cards/paella.jpg"
        title="Paella dish"
      /> */}
                      <CardContent>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="span"
                          onClick={() => {
                            // window.location.href = data.url
                            window.open(data.url);

                            //  window.location.href = data.Url

                            // console.log('test')
                          }}
                        >
                          {/* {data.description} */}
                          {data.div}
                          {/* This impressive paella is a perfect party dish and a fun meal to cook together with your
          guests. Add 1 cup of frozen peas along with the mussels, if you like. */}
                        </Typography>
                      </CardContent>
                      <CardActions disableSpacing>
                        {/* <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton> */}
                        <IconButton
                          aria-label="share"
                          onClick={() => {
                            // window.location.href = data.url
                            window.open(data.url);

                            //  window.location.href = data.Url

                            // console.log('test')
                          }}
                        >
                          <ShareIcon />
                        </IconButton>

                        {/* <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton> */}
                      </CardActions>
                      <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                          <Typography paragraph>Method:</Typography>
                          {/* <Typography paragraph>
            Heat 1/2 cup of the broth in a pot until simmering, add saffron and set aside for 10
            minutes.
          </Typography> */}
                          {/* <Typography paragraph>
            Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high
            heat. Add chicken, shrimp and chorizo, and cook, stirring occasionally until lightly
            browned, 6 to 8 minutes. Transfer shrimp to a large plate and set aside, leaving chicken
            and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and
            pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add
            saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
          </Typography> */}
                          {/* <Typography paragraph>
            Add rice and stir very gently to distribute. Top with artichokes and peppers, and cook
            without stirring, until most of the liquid is absorbed, 15 to 18 minutes. Reduce heat to
            medium-low, add reserved shrimp and mussels, tucking them down into the rice, and cook
            again without stirring, until mussels have opened and rice is just tender, 5 to 7
            minutes more. (Discard any mussels that don’t open.)
          </Typography> */}
                          {/* <Typography>
            Set aside off of the heat to let rest for 10 minutes, and then serve.
          </Typography> */}
                        </CardContent>
                      </Collapse>
                    </Card>

                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>

                    {/* {data.quote} */}
                  </TabPanel>
                </Grid>
              ))}
            </SwipeableViews>
          </div>
        </section>
      </Paper>

      {/* <img style={{width: '100%', borderTop: '.5rem solid #009688'}} src={resume} alt='resume' /> */}
    </div>
  );
}
