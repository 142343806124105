import React, { useState } from "react";import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import axios from './axios-stories';
import axios from 'axios';
import Button from '@material-ui/core/Button';






const useInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: event => {
        setValue(event.target.value);
      }
    }
  };
};


export default function AddressForm() {
  // const [name, setName] = useState("");
  // const [name, setName] = useState("");
  // const [name, setName] = useState("");
  // const [name, setName] = useState("");


  const { value:fullName, bind:bindFullName, reset:resetFullName } = useInput('');
  const { value:email, bind:bindEmail, reset:resetEmail } = useInput('');
  const { value:reason, bind:bindReason, reset:resetLReason } = useInput('');
  const { value:source, bind:bindSource, reset:resetSource } = useInput('');


  const handleSubmit = (evt) => {
    evt.preventDefault();


    const message = {
      name:fullName,
      email:email,
      reason:reason,
      source:source
    }

    axios.post('https://caines-146a1.firebaseio.com/messages.json', { message })  
      .then(res=>{
        // console.log(res);
        // console.log(res.data);
        // window.location = "/retrieve" //This line of code will redirect you once the submission is succeed
      })





    // alert(`Name = ${fullName},  Email = ${email}, Reason = ${reason}, Source = ${source}`, );
    resetFullName()
    resetEmail()
    resetLReason()
    resetSource()

    

}

  return (
    <React.Fragment>
      <br></br>

      {/* <form onSubmit={handleSubmit}>
      <label>
        Full Name:
        <input type="text" {...bindFullName} />
      </label>
      <label>
        Email:
        <input type="text" {...bindEmail} />
      </label>
      <input type="submit" value="Submit" />
    </form> */}

    
<form onSubmit={handleSubmit}>

      <Typography variant="h7" gutterBottom style={{fontFamily:'Archivo'}}>
        {/* This Form is under construction... */}
        Please use this form to submit questions, suggest FR timeline events, request collaboration, schedule me to speak/moderate your event, and provide feedback
      </Typography>
      <br></br>
      <br></br>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
          {...bindFullName}
            required
            id="firstName"
            name="firstName"
            label="Full name"
            fullWidth
            autoComplete="fname"
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="lname"
          />
        </Grid> */}
        <Grid item xs={12}>
          <TextField
          {...bindEmail}
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="email"

            // autoComplete="billing address-line1"
          />
        </Grid>
        {/* <Grid item xs={12}>
          <TextField
            id="address2"
            name="address2"
            label="Address line 2"
            fullWidth
            autoComplete="billing address-line2"
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>

          <TextField
                    {...bindReason}

            required
            id="reason"
            name="reason"
            label="Reason"
            fullWidth
          autoComplete="reason"
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <TextField 
          // id="state"   
          // name="state" 
          // label="State/Province/Region" 
          // fullWidth 

          id="country"
          name="country"
          label="Country"
          fullWidth
          autoComplete="billing country"

          />
        </Grid> */}
        {/* <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Zip / Postal code"
            fullWidth
            autoComplete="billing postal-code"
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <TextField
                {...bindSource}

            // required
            id="URL"
            name="URL"
            label="URL Source"
            fullWidth
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12}>
          {/* <FormControlLabel
            control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
            label="Job Inquiry?"
          /> */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    // className={classes.button}
                    // disabled
                  >
                    {'Submit'}
                  </Button>
        </Grid>
      </Grid>
    

    
      </form>
    </React.Fragment>
  );
}