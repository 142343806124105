import React from 'react';
// import resume from '../assets/images/resume.jpg';
// import Inspiration from "../components/Inspiration";
import CssBaseline from '@material-ui/core/CssBaseline';
// import FormControl from '@material-ui/core/FormControl';
// import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';
// import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import Step from '@material-ui/core/Step';
// import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
// import Stepper from '@material-ui/core/Stepper';
// import Step from '@material-ui/core/Step';
import Button from '@material-ui/core/Button';

import AddressForm from './../components/AddressForm';
// import PaymentForm from './../components/PaymentForm';
// import Review from './../components/Review';
import Link from '@material-ui/core/Link';




function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://material-ui.com/">
          Your Website
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  

  const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
  }));

  const steps = ['Personal Details'];

  // function getStepContent(step) {
  //   switch (step) {
  //     case 0:
  //       return <AddressForm />;
  //     case 1:
  //       return <PaymentForm />;
  //     case 2:
  //       return <Review />;
  //     default:
  //       throw new Error('Unknown step');
  //   }
  // }

export default function Contact () {
    const classes = useStyles();


    const [activeStep, setActiveStep] = React.useState(0);

    // const handleNext = () => {
    //   setActiveStep(activeStep + 1);
    // };
  
    const handleBack = () => {
      setActiveStep(activeStep - 1);
    };

    return (

        
        <div style={{backgroundColor:'black', color:'white'}}>
           <CssBaseline />
           {/* Categories  */}


<br></br>
<br></br>
<br></br>
<br></br>
<br></br>



<main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center"
          style={{
            paddingTop:'10px',
            
            fontFamily: "'Axiforma', sans-serif",
          }}
          >
            Contact Me
          </Typography>
          {/* <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper> */}
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  {/* Thank you for your order. */}
                  Message Sent!
                </Typography>
                <Typography variant="subtitle1">
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>       <br></br>
                <br></br>
          

                  {/* Your order number is #2001539. We have emailed your order confirmation, and will
                  send you an update when your order has shipped. */}
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <AddressForm></AddressForm>
                {/* {getStepContent(activeStep)} */}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    // disabled
                  >
                    {'Next'}
                  </Button> */}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
      </main>



       
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>







                         {/* <Inspiration/> */}

<br></br>
<br></br>
<br></br>


            {/* <img style={{width: '100%', borderTop: '.5rem solid #009688'}} src={resume} alt='resume' /> */}
        </div>

    )
}



