import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        // useNextVariants: true,
    },
    palette: {
        primary: {
            // light: '#52c7b8',
            // main: '#AB0505',
            // main: '#710303',
            // main: '#040000',
            main: '#e6e6e6',



            // main: '#661212',
            // main: '#F39325',


            // dark: '#00675b',
            // contrastText: '#fff',
        },
        secondary: {
            // main: '#00695c',
            // main: '#f3210c',
            main: '#fa0303',


        }
    },
    // overrides: {
    //     MuiDrawer: {
    //         paper: {
    //             // background: '#009688',
    //             '& *': { color: 'rgba(255, 255, 255, 0.7)' },
    //         },
    //     },
    // },
});



export default theme;
