import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
// import { loadCSS } from '/Users/henrylo/Documents/WorkSpace/React/henry/node_modules/fg-loadcss';
// import { loadCSS } from 'fg-loadcss';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import hero from "../assets/images/hero.jpg";
import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';
// import Grid from '@material-ui/core/Grid';
// import Link from '@material-ui/core/Link';

const useStyles = makeStyles ((theme)=>({

    mainFeaturedPost: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        // backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundImage: `url(${hero})`,

        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
      overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
      },
      mainFeaturedPostContent: {
        position: 'relative',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(6),
          paddingRight: 0,
        },
      },
    


    root: {
        backgroundImage: `url(${hero})`,
        // backgroundPosition: "center",
        // backgroundSize: "cover",
        // backgroundAttachment:'fixed',
        // height: '85vh',


        position: 'relative',
        // backgroundColor: theme.palette.grey[800],
        // color: theme.palette.common.white,
        // marginBottom: theme.spacing(4),
        // backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',



        // backgroundRepeat:'no-repeat',
// backgroundSize:'contain',






        
        
    },
    title: {
        textAlign: 'center',
        color: 'white',
        padding: 10,
        borderBottom: '.1rem solid white',
        // fontFamily: "'Courgette', sans-serif",
        // fontFamily: "'Dancing Script', sans-serif",
        // fontFamily: "'Montserrat', sans-serif",
        fontFamily: "'Noto Sans', sans-serif",
        // fontFamily: "'Caudex', sans-serif",
        // fontFamily: "'Nobile', sans-serif",
        // whiteSpace:'nowrap',
        // fontSize:'3.6rem',

        fontSize:'4.3rem',


        // fontFamily: 'Ramaraja',

        // fontFamily: 'Playfair Display',

        // fontFamily: 'Permanent Marker',
        position: 'relative',
        // margin: '0 auto',
        // '&:after': {
        //     content: "''",
        //     position: 'absolute',
        //     width: '100%',
        //     height: 8,
        //     left: 0,
        //     bottom: -5,
        //     borderBottom: '.1rem solid white',
        // },
    },
    subTitle: {
        textAlign: 'center',
        color: 'white',
        // fontFamily: 'Playfair Display',
        // fontSize: '1.2rem',
        fontSize: '1.6rem',

        fontFamily: "'Athiti', sans-serif",
        // fontFamily: "'Noto Sans', sans-serif",
        // fontFamily: "'Caudex', sans-serif",
        // fontFamily: "'Nobile', sans-serif",




        // fontFamily: 'Fauna One',
        // fontFamily: 'Permanent Marker',
        padding: 10,
    },
}));

export default function Intro() {
    const classes = useStyles();

    React.useEffect(() => {
        // loadCSS(
        //     'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
        //     document.querySelector('#font-awesome-css'),
        // );
    }, []);

    return (<div>
           <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${hero})` }}>
      {/* Increase the priority of the hero background image */}
      {<img style={{ display: 'none' }} src={hero} 
      alt={'post.imageText'} />}
      <div className={classes.overlay} />
      <CssBaseline />
            <Grid
                // style={{height: '100%'}}
                style={{height: '85vh'}}

                container
                alignItems="center"
            >
                <Container maxWidth='sm'>
                        <Grid item>
                            <Typography
                                variant='h1'
                                className={classes.title}
                                style={{
                                    // fontFamily:'Axiforma',
                                    fontSize:'3.5rem'
                                }}
                            >
                                Stephen Caines
                            </Typography>
                            <Typography
                                variant='subtitle1'
                                className={classes.subTitle}
                            >
                               
                             
                               Legal Technologist, Advocate,   &#38;  Innovator


                            </Typography>
                        </Grid>
                </Container>
               
              

             

            </Grid>
      
    </Paper>

       
    </div>);
}
