import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { Link } from 'react-router-dom';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
// import clsx from 'clsx';
// import Icon from '@material-ui/core/Icon';
// import Button from '@material-ui/core/Button';

import paperTexture from "../assets/images/paperTexture.jpg";

const useStyles = makeStyles({
  About: {
    backgroundImage: `url(${paperTexture})`,
    // borderTop: '.5rem solid #009688',
    //    borderBottom: '.5rem solid #009688',
    //    borderBottom: '.3rem solid #960000',
    //    borderBottom: '.2rem solid #F33725',
    borderBottom: ".3rem solid #050000",

    marginTop: -170,
    paddingTop: 180,
    paddingBottom: 100,
  },
  greeting: {
    // fontFamily: "'Athiti', sans-serif",
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "2rem",

    textTransform: "uppercase",
    fontWeight: "bold",
    padding: "2rem 0",
  },
  greetingText: {
    fontSize: "1.4rem",
    fontFamily: "'Athiti', sans-serif",
    textAlign: "center",

    // textTransform: 'uppercase',
    // fontWeight: 'bold',
    // padding: '2rem 0',
  },
  signature: {
    // fontFamily: 'Mr Dafoe',
    // fontFamily: 'Permanent Marker',

    padding: "4rem 0",
  },
  button: {
    padding: 0,
  },
  icon: {
    fontSize: 60,
  },
  iconDescription: {
    fontWeight: "bold",
    textAlign: "center",
  },
});

export default function About() {
  const classes = useStyles();

  // const navData = [
  //     {
  //         color: 'teal',
  //         icon: <Icon className={clsx(classes.icon, 'fa fa-file')} />,
  //         text: 'Resume',
  //         to: '/resume'
  //     },
  //     {
  //         color: '#0077B5',
  //         icon: <Icon className={clsx(classes.icon, 'fab fa-linkedin')} />,
  //         text: 'LinkedIn',
  //         to:'/linkedIn'
  //     },
  //     {
  //         color: 'black',
  //         icon: <Icon className={clsx(classes.icon, 'fab fa-github-square')} />,
  //         text: 'GitHub',
  //         to:'/gitHub'
  //     },
  // ];

  return (
    <section id="about" className={classes.About}>
      <CssBaseline />
      <Container maxWidth="md">
        <Typography
          variant="h2"
          align="center"
          className={classes.greeting}
          style={
            {
              // fontFamily: 'Archivo',
              // fontSize: '1.9rem',
            }
          }
        >
          Working on the Future
        </Typography>
        <Typography
          variant="body1"
          align="justify"
          className={classes.greetingText}
          style={{
            fontFamily: "Archivo",
            fontSize: "1.2rem",
            color: "#606060",
            // color: '#e2dfdf'
          }}
        >
          Hi, my name is Stephen! I am a legal technologist who is passionate
          about access to justice and privacy. As graduate of the University of
          Miami School of Law with a concentration in the Business of
          Innovation, Law &#38; Technology, I’ve spent a great deal of time
          focusing on the responsible and ethical use of technology. Currently,
          I am the residential fellow at CodeX: The Stanford Center for Legal
          Informatics where I primarily focus on facial recognition use by
          government and law enforcement in the United States. This site is
          meant to display my work, projects, and interests. Please don’t
          hesitate to reach out if you’d like to collaborate!
        </Typography>
        {/* <Typography
                    variant='h2'
                    align="center"
                    className={classes.signature}
                >
                    Henry Lo
                </Typography> */}
        <Grid container align="center" justify="center">
          {/* {navData.map(data => (
                        <Grid
                            item
                            xs={12}
                            sm={2}
                            key={data.text}
                        >
                            <nav>
                                <Button
                                    className={classes.button}
                                    style={{color: data.color}}
                                    component={Link}
                                    to={data.to}
                                >
                                    {data.icon}
                                </Button>
                            </nav>
                            <Typography
                                variant='h6'
                                className={classes.iconDescription}>
                                {data.text}
                            </Typography>
                        </Grid>
                    ))} */}
        </Grid>
      </Container>
    </section>
  );
}
