import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Avatar from "@material-ui/core/Avatar";
import SwipeableViews from "react-swipeable-views";
import hero2 from "../assets/images/hero2.jpg";
import steveJobs from "../assets/images/steveJobs.jpg";
import billGates from "../assets/images/billGates.jpg";
import jonathanGillette from "../assets/images/jonathanGillette.jpg";
import paulCookson from "../assets/images/paulCookson.jpg";
import cantHurtMeCover from "../assets/images/canthurtme.jpg";
// import QuoteIcon from '@material-ui/icons/FormatQuote';
import Paper from "@material-ui/core/Paper";
import { Box } from "@material-ui/core";
import whiteImage from "../assets/images/plain-white-background.jpg";
// import Container from '@material-ui/core/Container';

// import { makeStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
// import CardMedia from '@material-ui/core/CardMedia';
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
// import Avatar from '@material-ui/core/Avatar';
import IconButton from "@material-ui/core/IconButton";
// import Typography from '@material-ui/core/Typography';
import { red } from "@material-ui/core/colors";
// import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from "@material-ui/icons/Share";
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.3)",
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },

  bg: {
    backgroundImage: `url(${hero2})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    // height: '85vh',
    // height: '100vh',
    height: "100%",

    // borderBottom: '.5rem solid #009688',
    // borderBottom: '.5rem solid #960000',
    // borderBottom: '.5rem solid #821D1D',
    // borderBottom: '.14rem solid #FF1717',
    borderBottom: ".1rem solid #B90202",

    // borderBottom: '.1rem solid #050000',

    position: "relative",
  },
  noContainerGrid: {
    width: "100%",
    position: "absolute",
    left: "50%",
    top: "60%",

    transform: "translate(-50%, -50%)",
  },
  titleCard: {
    color: "white",
    marginTop: "10px",
    marginBottom: "7px",
    fontSize: "1.2rem",
    marginLeft: "-26px",
  },
  subheaderCard: { color: "white", marginLeft: "-24px" },
  title: {
    color: "white",
    // color:'black',

    // fontFamily: 'Helvetica',
    // fontWeight: 'bold',
    // textTransform: 'uppercase',
    // padding: 10,
    // fontFamily: "'Athiti', sans-serif",
    // fontFamily: "'Baloo Tamma', sans-serif",
    fontFamily: "'Montserrat', sans-serif",
    // fontFamily: "'Lato', sans-serif",

    fontSize: "2.4rem",
    // fontWeight: '900',

    // textTransform: 'uppercase',
    padding: "2rem 0",

    // fontWeight: 'bold',
    // fontSize:'3rem',
    // textShadow: '1px 1px 2px rgba(0, 0, 0, 0.6)'
    // textShadow: '2px 2px #050000',
    // textShadow: '2px 2px #fcfcfc',
    // textShadow: '2px 2px #fcfcfc',
  },
  titleVar: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: "2.7rem",

    // color: 'white',
    // // fontFamily: 'Helvetica',
    // fontWeight: 'bold',
    // textTransform: 'uppercase',
    // // top: '10%',
    // marginTop:'-1%',
    // padding: 10,
    // fontFamily: "'Athiti', sans-serif",
    // fontSize:'1.3rem'
  },
  titleBook: {
    fontFamily: "'Montserrat', sans-serif",

    // color: 'white',
    // // fontFamily: 'Helvetica',
    // fontWeight: 'bold',
    // // textTransform: 'uppercase',
    // // top: '10%',
    // marginTop:'-1%',
    // padding: 10,
    // fontFamily: "'Athiti', sans-serif",
    // fontSize:'0.7rem'
  },
  title2: {
    color: "white",
    // fontFamily: 'Helvetica',
    fontWeight: "bold",
    // textTransform: 'uppercase',
    // top: '10%',
    marginTop: "-1%",
    padding: 10,
    fontFamily: "'Athiti', sans-serif",
    fontSize: "1.3rem",
  },
  avatar: {
    margin: "0rem auto",
    width: 60,
    height: 60,
    border: "0rem solid #121212",
    boxShadow: "0 .3rem .2rem rgba(0, 0, 0, 0.3)",
  },
  bigIndicator: {
    height: 5,
  },
  quotes: {
    color: "white",
    textAlign: "center",
    // fontFamily:'Helvetica',
    fontFamily: "'Athiti', sans-serif",
  },
  quotationMark: {
    fontSize: 60,
  },
  quote: {
    margin: "0 auto",
  },
  name: {
    fontFamily: "'Athiti', sans-serif",
    fontSize: "1rem",
  },

  rootCard: {
    maxWidth: 345,
    backgroundColor: "#121212",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const avatarData = [
  {
    index: 3,
    name: "Book 1",
    src: paulCookson,
    quote: "",
    description:
      "Written by my former law professor and mentor Mary Anne Franks, this book places constitutional fundamentalism under a critical microscope and questions the antidemocratic consequences of blindly following this document. Rife with clear examples of where such fundamentalism repeatedly fails our modern world, she brilliantly questions current perceptions of this founding document",
    author: "Mary Anne Franks",
    bookName: "The Cult of the Constitution",
    url:
      "https://www.amazon.com/Cult-Constitution-Mary-Anne-Franks/dp/1503603229",
  },
  {
    // number: 1,
    index: 1,

    name: "Book 2",
    src: billGates,
    quote: "",
    description:
      "This book details the enjoyable yet informative journey of a UK neuroscientist to determine the origin of happiness. As he evaluates traditional, cultural, and biological notions, you’re sure to find some enlightening nuggets of wisdom.",
    author: "Dean Burnett ",
    bookName: "Happy Brain: Where Happiness Comes From, and Why",
    url:
      "https://www.amazon.com/Happy-Brain-Where-Happiness-Comes/dp/0393651347",
  },

  {
    number: 2,
    name: "Book 3",
    src: jonathanGillette,
    quote: "",
    description:
      "Tech companies have ballooned from fledging enterprises into dominant companies and institutions whose powers exceed that of some existing nation-states. Additionally, they are adopting a greater share positions and roles typically undertaken by governments themselves. This book evaluates the lasting implications of such a process.",
    author: "Lucie Greene",
    bookName: "Silicon States - The Power and Politics of Big Tech",
    url:
      "https://www.amazon.com/Silicon-States-Power-Politics-Future/dp/1640090711",
  },
  {
    // number: 0,
    index: 0,

    name: "Book 4",
    src: steveJobs,
    quote: "",
    description:
      "The authors of this book propose that the majority of market disruptions, shifts in power, and new economic relationships can largely be attributed to recent developments in three key areas. In great detail, they state how the old-world manner of innovation greatly contrasts the new world created in the wake of this technological disruption.",
    author: "Brynjolfson and McAaffe",
    bookName: "Machine Platform Crowd: Harnessing our Digital Future",
    url:
      "https://www.amazon.com/Machine-Platform-Crowd-Harnessing-Digital-ebook/dp/B01MAWT25I/ref=sr_1_2?crid=19SPH8QN1BDVD&dchild=1&keywords=machine+platform+crowd&qid=1585677254&s=books&sprefix=machine+platform+%2Cstripbooks%2C208&sr=1-2",
  },

  {
    index: 4,
    name: "Book 5",
    src: cantHurtMeCover,
    quote: "",
    description:
      "The story of David Goggins starts tragic and ends heroic. I read this book around the start of 2019 and it greatly increased my discipline while providing me with the confidence to undertake some major challenges and projects in my life.",
    author: "David Goggins ",
    bookName: "Can't Hurt Me ",
    url: "https://www.amazon.com/Cant-Hurt-Me-Master-Your/dp/1544512287",
  },
];

function TabPanel(props) {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.quotes}>
      {/* <QuoteIcon className={classes.quotationMark} /> */}
      <br></br>
      <br></br>
      {/* <br></br> */}

      <Typography variant="h5">{children}</Typography>
    </div>
  );
}

export default function Inspiration() {
  // const classes = useStyles();
  // const [expanded, setExpanded] = React.useState(false);
  const [expanded] = React.useState(false);

  // const handleExpandClick = () => {
  //     setExpanded(!expanded);
  // };

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  return (
    <Paper
      className={classes.mainFeaturedPost}
      style={{ backgroundImage: `url(${hero2})` }}
    >
      {/* Increase the priority of the hero background image */}
      {<img style={{ display: "none" }} src={hero2} alt={"post.imageText"} />}
      <div className={classes.overlay} />
      <section
        id="inspiration"
        // className={classes.bg}
      >
        {/* <Container maxWidth="lg"> */}

        <div
        // className={classes.noContainerGrid}
        >
          <br></br>
          <br></br>
          <br></br>
          <br></br>

          <Box
            display="flex"
            width={"100%"}
            height={60}
            //    bgcolor="lightgreen"
            alignItems="center"
            justifyContent="center"
            className="titleBoxClass"
          >
            <Paper
              className="titlePaperClass"
              //    classes = {{
              //     paper: {
              //         backgroundColor:'black'
              //     }
              // }}

              style={{
                // marginLeft:'-400px',
                marginLeft: "-40%",

                //   background:'blue',
                background: "rgb(253, 251, 251, .1)",
                borderRadius: "10px",

                //    padding:10
                paddingTop: 5,
                paddingRight: 6,
                //    paddingBottom: 10,
                //    paddingLeft: 6
              }}
            >
              <Paper
                //    classes = {{
                //     paper: {
                //         backgroundColor:'black'
                //     }
                // }}

                style={{
                  background: "rgb(251, 251, 251, 0.7)",
                  borderRadius: "10px",

                  //    background:'blue',
                  //    padding:10
                  // paddingTop: '-50px',
                  paddingRight: 0,
                  paddingBottom: 7,
                  paddingLeft: 6,
                }}
              >
                <Paper
                  elevation={10}
                  // className={classes.title}
                  style={{
                    // display: 'inline-block',
                    display: "inline-block",
                    padding: 2,
                    borderRadius: "7px",
                    marginLeft: "10px",

                    //   textAlign:'center'
                    // margin:'auto'
                  }}
                >
                  <Typography
                    style={{
                      minWidth: "10px",
                      fontFamily: "Axiforma",
                      // fontSize:'3.5rem'
                      //  fontSize:'2.3rem',
                      //  marginLeft:'2%'
                      //  textAlign:'center'
                      // margin:'auto'
                    }}
                    variant="h3"
                    align="center"
                    className={classes.titleVar}
                  >
                    {/* Subtitle */}
                    {/* Good Reads.. */}[ Reading List ]
                  </Typography>
                </Paper>
              </Paper>
            </Paper>
          </Box>

          <br></br>

          <AppBar position="static" style={{ margin: "1rem 0" }}>
            <Tabs
              classes={{ indicator: classes.bigIndicator }}
              value={value}
              onChange={handleChange}
              // variant="fullWidth"
              variant="scrollable"
              // style={{paddingTop:'5px'}}
            >
              {avatarData.map((data) => (
                <Tab
                  style={{
                    flexGrow: 1,
                    paddingTop: "5px",
                    // flexShrink:0
                    // margin:'auto'
                  }}
                  key={data.name}
                  label={
                    <div>
                      <Avatar
                        // alt={data.name}
                        // src={whiteImage}

                        alt={data.name}
                        src={data.src}
                        className={classes.avatar}
                      />

                      <Typography variant="caption" className={classes.name}>
                        {data.name}
                      </Typography>
                    </div>
                  }
                />
              ))}
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            {avatarData.map((data) => (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                // item xs={10} sm={6}
                // className={classes.quote}
                key={data.name}
              >
                <TabPanel
                  value={value}
                  index={data.index}
                  dir={theme.direction}
                >
                  <Card className={classes.rootCard}>
                    <CardHeader
                      avatar={
                        //   <Avatar aria-label="recipe" className={classes.avatar}>
                        //     {/* R */}
                        //   </Avatar>
                        <Avatar
                          alt={data.name}
                          src={whiteImage}
                          // alt={data.name}
                          // src={data.src}
                          className={classes.avatar}
                        />
                      }
                      action={
                        <IconButton aria-label="settings">
                          <MoreVertIcon />
                        </IconButton>
                      }
                      // title="Shrimp and Chorizo Paella"
                      title={data.bookName}
                      // subheader="September 14, 2016"
                      subheader={data.author}
                      classes={{
                        title: classes.titleCard,
                        subheader: classes.subheaderCard,
                        // padding:'10 30px'
                        // fontSize:'3rem'
                      }}
                    />
                    {/* <CardMedia
        className={classes.media}
        image="/static/images/cards/paella.jpg"
        title="Paella dish"
      /> */}
                    <CardContent>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{ fontFamily: "Archivo", color: "white" }}
                      >
                        {data.description}
                      </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                      {/* <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton> */}
                      <IconButton
                        aria-label="share"
                        onClick={() => {
                          window.location.href = data.url;
                          // console.log('test')
                        }}
                      >
                        <ShareIcon />
                      </IconButton>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Typography paragraph>Method:</Typography>
                      </CardContent>
                    </Collapse>
                  </Card>

                  <br></br>
                  <br></br>
                  <br></br>

                  {/* {data.quote} */}
                </TabPanel>
              </Grid>
            ))}
          </SwipeableViews>
        </div>
        {/* </Container> */}
      </section>
    </Paper>
  );
}
