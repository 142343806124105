import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Typography from '@material-ui/core/Typography';
import paperTexture from "../assets/images/paperTexture.jpg";
import WorkIcon from '@material-ui/icons/Work';
// import SchoolIcon from '@material-ui/icons/School';
// import BatteryIcon from '@material-ui/icons/Battery20';
import Paper from '@material-ui/core/Paper';

import Moment from 'react-moment';



// const useStyle = makeStyles({
    const useStyles = makeStyles((theme) => ({

    bg: {
        backgroundImage: `url(${paperTexture})`,
        borderBottom: '.5rem solid #474441',
        // backgroundColor:'white'
    },
    secondBg:{
        backgroundColor: 'rgba(71,68,65,0.1)',
        paddingBottom: 1,
    },
    arrow: {
        width: 0,
        height: 0,
        borderLeft: '.6rem solid transparent',
        borderRight: '.6rem solid transparent',
        borderBottom: '.6rem solid transparent',
        borderTop: '.6rem solid #009688',
        margin: '0 auto'
    },
    title: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        marginTop: 20,
        padding: 23,
    },
    paper: {
        //   width:800
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(6),
          padding: theme.spacing(3),
        },
      },
      layout: {
        width: 'auto',
        // width:200,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(1100 + theme.spacing(2) * 2)]: {
          width: 1100,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
    }));


// });

export default function EducationAndExperience (props) {



       

    const classes = useStyles();

    // useEffect(()=>console.log(props.frStories), [props])
// console.log(props)
    const [frStories, setFRStories] = useState(props.frStories);

    // useEffect(()=>



    
    //  setFRStories(props.frStories),
    // [props.frStories]
    // )


    useEffect(() => {
        async function fetchData() {
            await setFRStories(props.frStories)
          // You can await here
        //   const response = await MyAPI.getData(someId);
          // ...
        }
        fetchData();
      }, [props.frStories]); // Or [] if effect doesn't need props or state
    
    // console.log(frStories)

    
    // console.log('sorted', sortedActivities)



    // let storiesHolderRender = <React.Fragment>empty..</React.Fragment>

    // if (frStories) {

      
    //    }



       let educationAndExperienceData =[



        // Date 	                Event Title 	                                                                                                                    Category 	        Involved Parties            	                Submitted By	                         Url
        { Date: 'January 18th, 2020',     icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Clearview AI publicly revealed to the public	                                                        ', company: '  Private Industry	            ', location: '  Clearview AI	         ', submittedBy: '            Stephen Caines      ', Url: 'https://www.nytimes.com/2020/01/18/technology/clearview-privacy-facial-recognition.html' },
        { Date: 'February 19th, 2020',    icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Cornell researchers create Fawkes - countersurveillance tool            	                            ', company: '     Research 	                ', location: ' Cornell	                 ', submittedBy: '            Stephen Caines      ', Url: 'https://www.researchgate.net/publication/339374631_Fawkes_Protecting_Personal_Privacy_against_Unauthorized_Deep_Learning_Models' },
        { Date: 'February 28th, 2020',    icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Clearview AI Suffers Databreach that Reveals Client List	                                            ', company: '     Private Industry	        ', location: ' Clearview AI	             ', submittedBy: '            Stephen Caines      ', Url: 'https://www.vox.com/recode/2020/2/26/21154606/clearview-ai-data-breach' },
        { Date: 'January 29th, 2020	',    icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Fight for the Future Releases College Campus Scorecard	                                              ', company: '      Advocacy	                ', location: '  Fight for the Future 	 ', submittedBy: '            Stephen Caines      ', Url: 'https://www.vox.com/2020/1/29/21112212/facial-recognition-college-campus-scorecard' },
        { Date: 'February 18th, 2020',    icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Creator of Amazon Web Services States the Company Would Sell Facial Recognition to Foreign Governemnts  ', company: '    Amazon 	                 ', location: ' Amazon	                 ', submittedBy: '        Stephen Caines            ', Url: 'https://www.pbs.org/wgbh/frontline/article/amazon-aws-ceo-andy-jassy-defends-facial-recognition-sales-law-enforcement-says-would-sell-to-foreign-governments/' },
        { Date: 'November 30th, 2016',    icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Amazon Releases Rekognition Software Via Amazon Web Serivces            	                            ', company: '     Private Industry        	', location: '   Amazon	                 ', submittedBy: '            Stephen Caines        ', Url: 'https://aws.amazon.com/about-aws/whats-new/2016/11/introducing-amazon-rekognition/' },
        { Date: 'January 30th, 2020	',    icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Moscow Rolls Out Live Facial Recognition System 	                                                    ', company: '      Governemnet Surveillance  ', location: ' 	Russia	                 ', submittedBy: '            Stephen Caines        ', Url: 'https://www.theverge.com/2020/1/30/21115119/moscow-live-facial-recognition-roll-out-ntechlab-deployment' },
        { Date: 'January 29th, 2020	',    icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Facebook Settles Class Action BIPA-Facial Recogntiion Case for $550 Million 	                        ', company: '      Litigation 	            ', location: '   Facebook 	             ', submittedBy: '        Stephen Caines            ', Url: 'https://techcrunch.com/2020/01/29/facebook-will-pay-550-million-to-settle-class-action-lawsuit-over-privacy-violations/' },
        { Date: 'March 9th, 2020	',    icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: '   Singapore Announces Plan to Replaace all Photo IDs with Facial Recognition by 2022	               ', company: '       Governement Use           ', location: '	   Singapore	         ', submittedBy: '     Stephen Caines	              ', Url: 'https://www.dailymail.co.uk/sciencetech/article-8092881/Singapore-plans-launch-country-wide-facial-recognition-replace-photo-IDs-2022.html' },
        { Date: 'Febraur 19th, 2020	',    icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Trinamix Creates Tool To Secure FR Systems by telling between live, deceased, and masked persons	    ', company: '         Research                ', location: '	Trinamix 	             ', submittedBy: '       Stephen Caines	          ', Url: 'https://www.fastcompany.com/90465614/facial-recognition-is-easy-to-fool-this-tech-aims-to-fix-that' },
        { Date: 'January 15th, 2020	',    icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'NIST Releases Part III of Facial Recognition Tests                         '                              , company: '      	Research 	            ', location: '        NIST	 ', submittedBy: '     Stephen Caines	              ', Url: 'https://www.nist.gov/speech-testimony/facial-recognition-technology-part-iii-ensuring-commercial-transparency-accuracy' },
        { Date: 'January 29th, 2019	',    icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'IBM Releases Diversity in Faces 	                                  '                                 , company: '         Research 	            ', location: '          IBM	 ', submittedBy: '    Stephen Caines                ', Url: 'https://www.ibm.com/blogs/research/2019/01/diversity-in-faces/' },
        { Date: 'February 9th, 2018	',    icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Joy Bolumwini Releases Gender Shades 	                                           '                    , company: '            Research 	        ', location: '          MIT	 ', submittedBy: '       Stephen Caines	          ', Url: 'https://www.media.mit.edu/projects/gender-shades/overview/' },
        { Date: 'June 27th, 2020 	',    icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Somerville, MA Bans Governement Use of Facial Recognition                                               ', company: '      	Govenrement Regulation  ', location: '     MA	         ', submittedBy: '                   Stephen Caines', Url: 'https://www.masslive.com/politics/2019/06/this-massachusetts-city-just-passed-a-facial-recognition-technology-ban.html' },
        { Date: 'September 19th, 2020',   icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Berkeley, CA Bans Governemeut Use of Facial Recognition                                                 ', company: '         Govenrement Regulation  ', location: '     CA	         ', submittedBy: '                   Stephen Caines', Url: 'https://www.eff.org/deeplinks/2019/10/victory-berkeley-city-council-unanimously-votes-ban-face-recognition' },
        { Date: ' December 18th, 2019',   icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Alameda, CA Bans Governemeut Use of Facial Recognition 	                                                ', company: '        Govenrement Regulation   ', location: '    CA 	         ', submittedBy: '                   Stephen Caines', Url: 'https://www.eastbaytimes.com/2019/12/18/east-bay-city-becomes-latest-to-ban-use-of-facial-recognition-technology/' },
        { Date: ' December, 9th, 2019',   icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Oakland, CA Bans Governemeut Use of Facial Recognition 	                                                ', company: '       Govenrement Regulation    ', location: '   CA	         ', submittedBy: '                   Stephen Caines', Url: 'https://www.sfchronicle.com/bayarea/article/Oakland-bans-use-of-facial-recognition-14101253.php' },
        { Date: ' December 19th, 2019',   icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Brookline, MA Bans Governemeut Use of Facial Recognition                                                ', company: '       Govenrement Regulation    ', location: '    MA	         ', submittedBy: '                   Stephen Caines', Url: 'https://blog.tenthamendmentcenter.com/2019/12/brookline-massachusetts-passes-facial-recognition-ban/' },
        { Date: ' January 23rd, 2020',    icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Cambridge, MA Bans Government Use of Facial Recognition                                                 ', company: '       Governement Regulation    ', location: '    MA      	 ', submittedBy: '           Stephen Caines	      ', Url: 'https://www.google.com/search?q=cambridge+mcgovern+facial+recogntion+ordinance&oq=cambridge+mcgovern+facial+recogntion+ordinance&aqs=chrome..69i57j33.9105j0j7&sourceid=chrome&ie=UTF-8' },
        { Date: ' February 27th, 2020',   icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Northampton , MA Bans Government Use of Facial Recognition                                              ', company: ' 	    Governement Regulation  ', location: '	MA           ', submittedBy: '                  Stephen Caines', Url: 'https://www.masslive.com/news/2019/12/northampton-bans-facial-recognition-technology-becoming-third-community-in-massachusetts-to-do-so.html' },
        { Date: ' October 16th, 2016',    icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Georgetown Law Releases the Perpetual Line Up Ppaer 	                                                ', company: '          Governement Regulation ', location: 'Georgetown Law   ', submittedBy: '        Stephen Caines	          ', Url: 'https://www.perpetuallineup.org/' },
        { Date: ' February 25th, 2020',   icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Springfield, MA Bans Governement Use of Facial Recognition                                             ', company: '     	Governement Regulation  ', location: '	MA	         ', submittedBy: '           Stephen Caines	      ', Url: 'https://www.masslive.com/springfield/2020/02/springfield-city-council-passes-facial-recognition-moratorium.html' },
        { Date: ' January 1st, 2016 ',    icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Proeject Green Light Goes Live in Detroit 	                                                            ', company: '          Governement Use       ', location: '    MI	         ', submittedBy: '              Stephen Caines	  ', Url: 'https://detroitmi.gov/departments/police-department/project-green-light-detroit' },
        { Date: ' November 22nd, 2019 ',  icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'Tenants of Crown Plaza Sucessfully Prevent FR Integration into their buidling                           ', company: '     	Private Industry 	    ', location: '    NY	         ', submittedBy: '            Stephen Caines      ', Url: 'https://www.theguardian.com/cities/2019/may/29/new-york-facial-recognition-cameras-apartment-complex' },
        { Date: ' October 9th, 2019	',    icon: <WorkIcon/>, logoBgColor: '#00675b', Event_Title: 'California Passes Bill 1215 that prevents integration fo the technology with police cameras             ', company: '     	Governemnt Regulation	', location: '    CA           ', submittedBy: ' Stephen Caines	                  ', Url: 'https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201920200AB1215' },




        
        // {
        //     date: '2018 - present',
        //     icon: <WorkIcon/>,
        //     logoBgColor: '#00675b',
        //     title: 'Junior Developer',
        //     company: 'TRARA INC',
        //     location: 'San Jose, CA',
        //     description: 'Front End Developer, User Experience, Visual and Responsive Design'
        // },
        // {
        //     date: '2016 - 2017',
        //     icon: <WorkIcon/>,
        //     logoBgColor: '#00675b',
        //     title: 'Accounts Manager',
        //     company: 'Well Metal Craft',
        //     location: 'Taichung, Taiwan',
        //     description: 'Manufacture and Export, Sales, Schwarzkopf Display Project Team Lead'
        // },
        // {
        //     date: '2014 - 2016',
        //     icon: <WorkIcon/>,
        //     logoBgColor: '#00675b',
        //     title: 'Warehouse Manager',
        //     company: 'Strike Industries',
        //     location: 'Santa Ana, CA',
        //     description: 'Firearm Parts and Accessories, Manufacture, Online Marketing'
        // },
        // {
        //     date: 'Dec 2013',
        //     icon: <SchoolIcon/>,
        //     logoBgColor: '#009688',
        //     title: 'Bachelor of International Business',
        //     company: 'California State University, Fullerton',
        //     location: 'Fullerton, CA',
        //     description: 'Mihaylo College of Business and Economics, International Studies'
        // },
    ];

    let iconHolder = (<WorkIcon/>)

    if (frStories)  {
         educationAndExperienceData = frStories.Sheet1.slice(1).sort(function(a, b) {
            var c = new Date(a.Date);
            var d = new Date(b.Date);
            return d-c;

            // return c-d;
        });

        // console.log('sorted', sortedActivities)
        // console.log('sortedData', educationAndExperienceData)

        iconHolder = (<WorkIcon 
            // color="primary"
            // color='#8B8C8D'
            
            style={{
            // fill:'red',
            // color:'white'
            // backgroundColor:'red'
    }} />)

    }


    // const dateToFormat = '1976-04-19T12:59-0500'

    return (
        
        <section
            id='educationAndExperience'
            // className={classes.bg}       
        >

<main className={classes.layout}>
    

<Paper className={classes.paper} style={{maxHeight: 600, overflow: 'auto', 
// backgroundColor:'transparent'
}}>
{/* {storiesHolderRender} */}
{/* <Moment format="YYYY/MM/DD">{dateToFormat}</Moment> */}
{/* <Moment format="MMMM DD YYYY">{dateToFormat}</Moment> */}


<div 
className={classes.secondBg}
>
                <div className={classes.arrow} />
                <Typography
                    // className={classes.title}
                    className='TimelineTitle'
                    variant='h3'
                    align='center'
                    style={{fontFamily:'Axiforma',
                    // fontSize:'1.2rem !important',
                    //  color:'#e2dfdf',
                    }}
                >
                    Facial Recognition Events
                </Typography>
                <br></br>
                {/* <br></br> */}
                <Typography style={{
                    marginTop:'-11px',
                    fontFamily: 'Archivo',
                    fontSize: '1rem',
                    // color: '#e2dfdf' 
                    
                    
                }}
                    variant='subtitle1'
                    align='center'
                >
                    This timeline is meant to capture some of the most significant events in the field of facial recognition. 
                    <br></br>
                    Please contribute new stories via the ‘Contact Me’ tab!
                </Typography>

                {/* {frStories} */}
                <br></br>

                <VerticalTimeline key={'1'}>
                    {educationAndExperienceData.map((data, index) => (
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            key={index}
                            // date={data.Date}
                            // date={<div>test Date</div>}
                            // date={<Moment format="MMMM DD YYYY">{dateToFormat}</Moment>}
                            date={<Moment format="MMMM DD YYYY">{data.Date}</Moment>}

                            // date={new Date(data.Date)}

                            iconStyle={{ 
                                background: '#00675b',
                                // data.logoBgColor, 
                                color: '#fff' 
                            }}
                            // icon={data.icon}
                            icon={iconHolder}

                        >
                            <h2 className="vertical-timeline-element-title">
                                {data.Event_Title}
                            </h2>
                            <br></br>
                            <h3 className="vertical-timeline-element-subtitle">
                                 {/* {data.company} */}
                                 {data.Category}

                            </h3>
                            {/* <h4 className="vertical-timeline-element-subtitle">
                                Parties involed: {data.location}
                            </h4> */}
                            <p style={{wordWrap:'break-word'}} onClick={()=> {
                                //  window.location.href = data.Url
                                 window.open(data.Url)

                            }}>
                                    {data.Url}

                                {/* {data.description} */}
                            </p>
                            <br></br>
                            {/* <a 
                            style={{wordWrap:'break-word'}} 
                            // style={{padding:'20px'}}
                            href={data.description}>{data.description}</a> */}

                        </VerticalTimelineElement>
                    ))}
                    {/* <VerticalTimelineElement
                        date="18 years of happiness"
                        iconStyle={{ background: '#52c7b8', color: '#fff' }}
                        icon={<BatteryIcon />}
                    /> */}
                </VerticalTimeline>





                

               
            </div>
          
          {/* <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper> */}
          
        </Paper>
        </main>



        
            
        </section>
    )
}

