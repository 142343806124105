import React from 'react';
// import resume from '../assets/images/resume.jpg';
// import Inspiration from "../components/Inspiration";


// import StephenAvatar from '../components/StephenAvatar';
// import About from "../components/About";
// import Intro from "../components/Intro";

// import image1 from './../assets/images/caroImage.jpg';
// import image1 from './../assets/images/IMG_9074.png';

// import image1 from "./../assets/images/hero24.jpg"

// import image2 from './../assets/images/caroImage.jpg';
// import image3 from './../assets/images/caroImage.jpg';
// import hero2 from "../assets/images/hero2.jpg";
// import screenshot1 from './../assets/images/screenshot1.png'
// import screenshot2 from './../assets/images/screenshot2.png'
// import screenshot3 from './../assets/images/screenshot3.png'
// import screenshot4 from './../assets/images/screenshot4.png'
import CoronAtlas from './../assets/images/coronatlas.png'

// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


import { makeStyles } from '@material-ui/core/styles';

// import paperTexture from "../assets/images/paperTexture2.jpg";
import CssBaseline from '@material-ui/core/CssBaseline';
// import hero from "../assets/images/hero.jpg";
// import imageAurora from "./../assets/images/aurora-kreativ-UN4cs4zNCYo-unsplashDark.jpg"
// import imageAurora from "./../assets/images/jason-leung-r93UZeT3AQE-unsplash.jpg"
// import imageAurora from "./../assets/images/maarten-van-den-heuvel-gZXx8lKAb7Y-unsplash.jpg"


import imageCircuit from "./../assets/images/hero2proj.jpg"

import Card from '@material-ui/core/Card';
// import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
// import Collapse from '@material-ui/core/Collapse';
// import Avatar from '@material-ui/core/Avatar';
// import IconButton from '@material-ui/core/IconButton';
// import Typography from '@material-ui/core/Typography';
// import { red } from '@material-ui/core/colors';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import ShareIcon from '@material-ui/icons/Share';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import whiteImage from '../assets/images/plain-white-background.jpg';
import Paper from '@material-ui/core/Paper';




// import './../App.css';
// import './../style/media.css';
import './../style/media.css'



// import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';

// import Paper from '@material-ui/core/Paper';
// import Moment from 'react-moment';


// import WorkIcon from '@material-ui/icons/Work';
// import paperTexture from "../assets/images/paperTexture.jpg";

import EducationAndExperience from "../components/EducationAndExperience";
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';



// const useStyles = makeStyles({
//     About: {
//         backgroundImage: `url(${paperTexture})`,    
//         // borderTop: '.5rem solid #009688',
//     //    borderBottom: '.5rem solid #009688',
//     //    borderBottom: '.3rem solid #960000',
//     //    borderBottom: '.2rem solid #F33725',
//        borderBottom: '.3rem solid #050000',





//         marginTop: -170,
//         paddingTop: 180,
//         paddingBottom: 100,
//     },
//     greeting: {
//         // fontFamily: "'Athiti', sans-serif",
//         fontFamily: "'Montserrat', sans-serif",
//         fontSize:'2rem',

//         textTransform: 'uppercase',
//         fontWeight: 'bold',
//         padding: '2rem 0',
//     },
//     greetingText: {
//         fontSize:'1.4rem',
//         fontFamily: "'Athiti', sans-serif",
//         textAlign: 'center',

//         // textTransform: 'uppercase',
//         // fontWeight: 'bold',
//         // padding: '2rem 0',
//     },
//     signature:{
//         // fontFamily: 'Mr Dafoe',
//         // fontFamily: 'Permanent Marker',

//         padding: '4rem 0',
//     },
//     button: {
//         padding: 0,
//     },
//     icon: {
//         fontSize: 60
//     },
//     iconDescription: {
//         fontWeight: 'bold',
//         textAlign: 'center',
//     }
// });





const useStyles = makeStyles(theme => ({

    mainFeaturedPost: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    mainFeaturedPostContent: {
        position: 'relative',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
            paddingRight: 0,
        },
    },








    bg: {
        // backgroundImage: `url(${hero2})`,
        // backgroundImage: `url(${imageAurora})`,

        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundAttachment: 'fixed',
        // height: '85vh',
        // height: '100vh',
        height: '100%',

        // borderBottom: '.5rem solid #009688',
        // borderBottom: '.5rem solid #960000',
        // borderBottom: '.5rem solid #821D1D',
        // borderBottom: '.14rem solid #FF1717',
        borderBottom: '.1rem solid #B90202',

        // borderBottom: '.1rem solid #050000',




        position: 'relative',
    },
    noContainerGrid: {
        // paddingBottom:'1000px',
        // marginTop:'100px',
        // marginBottom:'100px',

        // height:'110%',
        width: '100%',
        position: 'absolute',
        left: '50%',
        // top: '50%',
        top: '60%',

        // top: '37%',

        transform: 'translate(-50%, -50%)'
    },
    titleCard: {
        fontSize: '1.6rem',
        // color:'black'
        marginTop: '10px',
        marginBottom: '7px'
    },

    titleVar: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '2.7rem',


        // color: 'white',
        // // fontFamily: 'Helvetica',
        // fontWeight: 'bold',
        // textTransform: 'uppercase',
        // // top: '10%',
        // marginTop:'-1%',
        // padding: 10,
        // fontFamily: "'Athiti', sans-serif",
        // fontSize:'1.3rem'

    },
    titleBook: {
        fontFamily: "'Montserrat', sans-serif",

        // color: 'white',
        // // fontFamily: 'Helvetica',
        // fontWeight: 'bold',
        // // textTransform: 'uppercase',
        // // top: '10%',
        // marginTop:'-1%',
        // padding: 10,
        // fontFamily: "'Athiti', sans-serif",
        // fontSize:'0.7rem'

    },
    title2: {
        color: 'white',
        // fontFamily: 'Helvetica',
        fontWeight: 'bold',
        // textTransform: 'uppercase',
        // top: '10%',
        marginTop: '-1%',
        padding: 10,
        fontFamily: "'Athiti', sans-serif",
        fontSize: '1.3rem'

    },
    avatar: {
        margin: '.2rem auto',
        width: 60,
        height: 60,
        border: '.1rem solid white',
        boxShadow: '0 .3rem .2rem rgba(0, 0, 0, 0.3)',
    },
    bigIndicator: {
        height: 5,
    },
    quotes: {
        color: 'white',
        textAlign: 'center',
        // fontFamily:'Helvetica',
        fontFamily: "'Athiti', sans-serif",

    },
    quotationMark: {
        fontSize: 60,
    },
    quote: {
        margin: '0 auto',
    },
    name: {
        fontFamily: "'Athiti', sans-serif",
        fontSize: '1rem'

    },




    rootCard: {
        // maxWidth: 345,
        // maxWidth: 545,
        maxWidth: 945,


    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    // avatar: {
    //     backgroundColor: red[500],
    //     // backgroundColor: red[400],

    //     // backgroundColor: '#030000',

    // },







    root: {
        // backgroundImage: `url(${hero})`,
        backgroundImage: `url(${imageCircuit})`,
        backgroundPosition: "center",
        // backgroundSize: "contain",
        backgroundSize: "cover",
        backgroundAttachment: 'fixed',
        height: '95vh',
    },
    title: {
        padding: '2rem 0',
        textAlign: 'center',
        color: 'white',
        // borderBottom: '.1rem solid white',
        fontFamily: "'Noto Sans', sans-serif",
        fontSize: '3.3rem',
        position: 'relative',


        borderBottom: '.1rem solid white',

        // border-image: linear-gradient(to left, #743ad5, #d53a9d);



    },
    subTitle: {
        textAlign: 'center',
        color: 'white',
        // fontFamily: 'Playfair Display',
        // fontSize: '1.2rem',
        fontSize: '1.6rem',

        // fontFamily: "'Athiti', sans-serif",
        // fontFamily: "'Noto Sans', sans-serif",
        // fontFamily: "'Caudex', sans-serif",
        // fontFamily: "'Nobile', sans-serif",




        // fontFamily: 'Fauna One',
        // fontFamily: 'Permanent Marker',
        padding: 10,
    },

    cardRoot: {
        // backgroundColor:'transparent',
        // backgroundColor:'rgba(39, 38, 38, .4)',
        backgroundColor:'rgba(19, 19, 19, .8)',

        // background:'linear-gradient(90deg, rgba(91,91,91,1) 0%, rgba(64,64,64,1) 35%, rgba(69,69,69,1) 75%, rgba(92,92,92,1) 100%)',
        backdropFilter: 'blur(2px)',
        marginTop:'-30px'

        // maxWidth: 645,

    },
    cardRoot2: {
        // backgroundColor:'transparent',
        backgroundColor:'transparent',
        backdropFilter: 'blur(2px)',
        marginTop:'-25px'

        // maxWidth: 645,

    }


}));








export default function CurrentProjects(props) {



    const classes = useStyles();

    // const [frStories, setFRStories] = useState(props.frStories);


    // useEffect(() => {
    //   async function fetchData() {
    //       await setFRStories(props.frStories)
    //     //   console.log(frStories)
    //     // You can await here
    //   //   const response = await MyAPI.getData(someId);
    //     // ...
    //   }
    //   fetchData();
    // }, [props.frStories]); // Or [] if effect doesn't need props or state









    return (
        <div 
        // style={{backgroundColor:'#303030'}}
        // style={{backgroundColor:'#171717'}}
        // style={{backgroundColor:'#010101'}}
        style={{backgroundColor:'#2f2f2f'}}



        >
            {/* <Intro/> */}

            <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${imageCircuit})` }} elevation={0}>
                {/* Increase the priority of the hero background image */}
                {<img style={{ display: 'none' }} src={imageCircuit} alt={'post.imageText'} />}
                <div className={classes.overlay} />
                <section
                    id='projects'
                    // className={classes.root} 
                    style={{
                        borderBottom: '.9rem solid #050000',
                    }}
                >
                    <CssBaseline />
                    <div style={{
                        color: 'white',
                        // display:(matches ? 'none' : '')
                    }}>
                        {/* {matches ? <div>matches</div> : <div>doesnt match</div>} */}

                    </div>


                    <Grid
                        // style={{ height: '100%' }}
                        style={{ height: '85vh' }}

                        container
                        alignItems="center"
                    >
                        <React.Fragment>


                            <Container
                                //  maxWidth='sm'
                                style={{
                                    // maxWidth:'82vw',
                                    maxWidth: '78vw',
                                    // maxWidth:'58vw',
                                    marginTop: '-50px',
                                    // backgroundColor: 'black',
                                    background: 'linear-gradient(to right, rgba(18, 0, 0, .9), rgba(18, 0, 0, 1), rgba(0, 0, 0, .8), rgba(0, 0, 0, .6),  rgba(0, 0, 0, .8), rgba(0, 0, 0, .8), rgba(0, 0, 0, .7) , rgba(15, 8, 08, 0.8), rgba(3, 14, 7, 0.6), rgba(3, 4, 7, 0.9) )',
                                    backdropFilter: 'blur(2px)',
                                    borderRadius: '36px',
                              }}>
                                <Grid item
                               
                                >
                                    <Typography
                                        variant='h1'
                                        className={classes.title}
                                        // className="modulez"

                                        style={{
                                            textShadow: '1px 1px 10px black',
                                            fontFamily:'Axiforma'
                                            
                                        }}>
                                        Current Projects
                            </Typography>
                                    <Typography
                                        variant='subtitle1'
                                        className={classes.subTitle} 
                                        style={{
                                            textShadow: '1px 1px 10px black',
                                            fontFamily: 'Archivo',
                                            fontSize: '1.5rem',
                                            color: '#e2dfdf' 
                                        }}>
                                        Personal  &#38; Professional
                            </Typography>
                                    <br></br>
                                </Grid>
                            </Container>
                        </React.Fragment>
                    </Grid>
                </section>
            </Paper>






            <Grid container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid item style={{ padding: '50px' }}>
                    <div
                        //   className='MeetCodexVideo'
                        style={{
                            // minWidth: '860px !important',
                            // minHeight: 'auto'
                        }}>
                        <Card elevation={8} style={{ padding: '20px' }}
                            className={classes.cardRoot}>
                            <CardActionArea>

                                <CardContent>

                                    <Grid container

                                        direction="row"
                                        justify="center"
                                        alignItems="center">

                                        <Grid item
                                        style={{paddingTop:'30px'}}

                                        >


                                            <iframe style={{
                                                //  minWidth:'260px'
                                                border:'1px solid black'
                                               
                                            }}
                                                title="Meet Codex Youtube video"
                                                className='MeetCodexVideo'

                                                // width="100%" 
                                                // height="auto"
                                                height="100%"
                                                frameborder="0"
                                                

                                                // width="560" 
                                                // height="315" 

                                                src="https://www.youtube.com/embed/bm75IOYY9t0" />


                                        </Grid>

                                    </Grid>



                                    <Typography gutterBottom variant="h5" component="h2" style={{ paddingTop: '23px', 
                                    // textDecoration:'underline',
                                    fontFamily:'Archivo',
                                    // fontFamily:'Axiforma',
                                    fontSize:'1.5rem',
                                     color:'#d1d1d1',
                                     
                                     
                                    }}>
                                        LEX Talk (FutureLaw 2020): The Many Faces of Facial Recognition
          </Typography>
                                    <Typography 
                                    style={{ 
                                        // paddingTop: '10px', 
                                    // textDecoration:'underline',
                                    fontFamily:'Archivo',
                                    fontSize:'1rem',
                                     color:'#d1d1d1',
                                     paddingBottom:'5px',
                                    // borderBottom:'2px solid black',
                                    paddingTop:'10px',
                                    borderTop:'1px solid white'
                                    }}
                                    
                                    variant="body2"
                                        color="textSecondary"
                                        component="p">
                                        Facial recognition development and use has been exponentially rising in both the private and public sector. It is heralded by some as the ultimate crime fighting tool and admonished by others as the end of privacy. This talk will examine both views and identify the legal, technical, and regulatory considerations required for the safe deployment of this technology.
          </Typography>

                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button onClick={() => {
                                    // window.location.href = data.url
                                    window.open("https://www.youtube.com/embed/bm75IOYY9t0")


                                    //  window.location.href = data.Url



                                    // console.log('test')
                                }}

                                    style={{
                                        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                                        borderRadius: 3,
                                        border: 0,
                                        color: 'white',
                                        height: 48,
                                        padding: '0 30px',
                                        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                                        fontFamily:'Axiforma',
                                        // fontFamily: 'Archivo',
                                    }} >
                                    Source
        </Button>
                                {/* <Button >
          Learn More
        </Button> */}
                            </CardActions>

                        </Card>




                    </div>
                </Grid>
            </Grid>





            {/* <div backgroundColor="white"> */}



            <Card
                elevation={0}
                style={{ padding: '20px',  }}
                className={classes.cardRoot2}>
                <CardActionArea>
                    <CardContent>
                        <EducationAndExperience {...props} />

                    </CardContent>
                </CardActionArea>
            </Card>



            {/* <EducationAndExperience {...props} /> */}

            {/* </div> */}



            <div>
                <br></br>
                <br></br>
                <br></br>


                {/* CoronAtlas */}

                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item style={{ padding: '50px' }}>
                        <div
                            //   className='MeetCodexVideo'
                            style={{
                                // minWidth: '860px !important',
                                // minHeight: 'auto'
                            }}>
                            <Card elevation={6} style={{ padding: '20px' }}
                                className={classes.cardRoot}>
                                <CardActionArea>

                                    <CardContent style={{ backgroundColor: 'black' }}

                                        onClick={() => {
                                            // window.location.href = data.url
                                            window.open("https://coronatlas.law.stanford.edu")


                                            //  window.location.href = data.Url



                                            // console.log('test')
                                        }}
                                    >

                                        <Grid container

                                            direction="row"
                                            justify="center"
                                            alignItems="center">

                                            <Grid item xs={6}

                                            >


                                                {/* <iframe style={{
                                                //  minWidth:'260px'
                                            }}
                                                title="Meet Codex Youtube video"
                                                className='MeetCodexVideo'

                                                // width="100%" 
                                                // height="auto"
                                                height="100%"

                                                // width="560" 
                                                // height="315" 

                                                src="https://www.youtube.com/embed/bm75IOYY9t0" /> */}
                                                <img src={CoronAtlas} alt="CoronAtlas Showcase"
                                                    width="100%"
                                                    height="100%"
                                                >
                                                </img>


                                            </Grid>

                                        </Grid>



                                        <Typography gutterBottom variant="h5" component="h2" style={{ paddingTop: '10px', color: 'white', fontFamily:'Axiforma' }}>
                                            CoronAtlas - CodeX
          </Typography>
                                        {/* <Typography variant="body2"
                                        color="textSecondary"
                                        component="p">
                                        Facial recognition development and use has been exponentially rising in both the private and public sector. It is heralded by some as the ultimate crime fighting tool and admonished by others as the end of privacy. This talk will examine both views and identify the legal, technical, and regulatory considerations required for the safe deployment of this technology.
          </Typography> */}

                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    {/* <Button onClick={() => {
                                        window.open("https://www.youtube.com/embed/bm75IOYY9t0")


                                    }}

                                        style={{
                                            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                                            borderRadius: 3,
                                            border: 0,
                                            color: 'white',
                                            height: 48,
                                            padding: '0 30px',
                                            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                                        }} >
                                        Source
        </Button> */}

                                </CardActions>

                            </Card>




                        </div>
                    </Grid>
                </Grid>






            </div>

            {/* <Inspiration/> */}


        </div>


    )
}



